@font-face {
    font-family: 'HalvarEng';
    src: url('../fonts/HalvarEng-Rg.woff2') format('woff2'),
         url('../fonts/HalvarEng-Rg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'HalvarEng';
    src: url('../fonts/HalvarEng-Th.woff2') format('woff2'),
         url('../fonts/HalvarEng-Th.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'RF Rufo';
    src: url('../fonts/RFRufo-Regular.woff2') format('woff2'),
        url('../fonts/RFRufo-Regular.woff') format('woff'),
        url('../fonts/RFRufo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SuisseIntl';
    src: url('../fonts/SuisseIntl-SemiBold.woff2') format('woff2'),
        url('../fonts/SuisseIntl-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'SuisseIntl';
    src: url('../fonts/SuisseIntl-Regular.woff2') format('woff2'),
        url('../fonts/SuisseIntl-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: DrukCyr;
  font-display: swap;
  src: url("/assets/templates/fonts/drukcyr-bold.woff2") format("woff2"), url("/assets/templates/fonts/drukcyr-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}