.select2-container--default .select2-selection--single {
    border: none;
    background: #20324c;
    height: auto;
    border-radius: 0;
}
.gslide-image {
    background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 10px 10px 10px 30px;
    color: #fff;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.recaptchav-item {
    margin-top: 15px;
    margin-bottom: 20px;
}
.select2-results {
    background: #20324c;
}
.select2-dropdown {
    border: none;
}
.select2-container--default .select2-results>.select2-results__options {
    max-height: 220px;
    margin: 0 0 5px 9%;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background: #20324c;
}
.select2-results__option[aria-selected] {
    color: #ffffff;
    line-height: 1.1em;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #ed1c24 transparent transparent transparent;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px;
    right: 10px;
}
#mse2_mfilter {
    
    #mse2_filters {
        display: flex;
        margin-bottom: 30px;
    }
}
.mse2-row {
    margin: 10px 0;
    p {
        display: inline;
    }
}
fieldset {
    padding: 0;
}
.compare--category .compare_title {
    display: block !important;
}
.comparison.added.can_compare .compare_wrapper {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
}
.comparison.added.can_compare .compare_items {
    display: flex;
    flex-wrap: wrap;
}
.comparison-table .comparison-remove.del_compare {
    background: #ff0a14 url(../img/delete.png) 92% no-repeat;
    color: #fff;
    text-decoration: none;
    padding: 0 25px 2px 5px;
    font-size: 13px;
    position: static;
}
.comparison a.comparison-link, .comparison a.comparison-go {
    color: #ff0a14;
}
.comparison.added a.comparison-go {
    display: block !important;
}

.compare_page a.comparison-link {
    background: transparent;
    cursor: default;
    color: #9b9b9b;
    cursor: pointer;
}
.compare_page a.comparison-link.active {
    background: transparent;
    cursor: default;
    color: #ff0a14;
}
.compare_page .comparison-corner {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    justify-content: flex-end;
    
}
.compare_page table tr td.comparison-corner {
    width: auto;
    text-align: left;
}
.comparison-corner .print {
    padding-top: 30px;
    background-position-y: bottom;
}
.compare_page .comparison-table .field-price {
    font-weight: normal;
}
.comparison-table td.field-pagetitle {
    font-weight: bold;
    text-transform: uppercase;
}
.prod_block .comparison.comparison-products {
    text-align: right;
}
.prod_block .descr .comparison .comparison-go {
    display: none !important;
}
.filters ul {
    margin: 0 0 0 9%;
}
.filters .slct_dropdown {
    display: none;
    &.active {
        display: block;
    }
    li.hidden {
        display: none;
    }
}
.article img {
    height: auto;
    width: auto;
}


