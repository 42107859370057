.banner, .catalog, .bestsellers, .news-block, .faq, .contacts-h{
    background: white;
}

#menu:not(.mm-menu){
display: none;
}

html{
  height: auto !important;
}
.header .mm-menu {
    display: none;
}
.mm-menu {
    opacity: 0;
}
.mm-menu--opened{
  z-index: 99999 !important;
  opacity: 1;
}

.header-n{
  z-index: 3 !important;
}
.footer-n{
  position: relative !important;
  z-index: 1 !important;
}



h1{
    font-family: DrukCyr, OpenSans, sans-serif !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 54px !important;
    letter-spacing: 0.03em !important;
    text-transform: uppercase !important;
}

.h2{
    font-family: DrukCyr, OpenSans, sans-serif !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 54px !important;
    letter-spacing: 0.03em !important;
    text-transform: uppercase !important;
}

.slide-up span{
    font-size: 16px !important;
    font-weight: 500 !important;
    pointer-events: none !important;
    transition: all 0.2s ease-in-out;
}

.slide-up:hover span{
   color: #FF0A14;
}

.submit-btn--new{
    //background: #ed1c24;
    text-transform: capitalize;
    margin: 20px 0;
    //font-size: 14px;
}
.lb-outerContainer {
    width: 500px !important;
    height: auto !important;
    min-height: 300px !important;
}

.faq-item__title.faq-item__title-js.item-header:hover{
    color: #FF0A14;
}

.nav-mobile__sub-link {
    color: #6e6e6e !important;
    line-height: 1.4;
    font-size: 12px;

}
.service_center {
    margin-bottom: 20px;
}
@media(max-width: 767px){
    .service-header-table td {
    display: block;
    width: 100% !important;
}

}

.input {
    font-family:'PT Sans', sans-serif;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    background: #fff;
    margin: 0;
    font-size: 16px;
    @include media(1460px) {
        font-size: 14px;
    }
    &.error {
        border-color: #FF0A14 !important;
        color: #FF0A14 !important;
    }
}
textarea.input {
    resize: none;
    height: 100px;
    font-size: 16px;
}

.sidebar {
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #F9F9F9;
    padding: 30px;
    @include mediaLaptop {
        padding: 20px;
    }
    @include mediaPhone {
        padding: 15px;
    }
}

.footer__bottom{
    color: #8C9CB3;
    padding: 30px 0;
}
.site-dev{
        display: flex;
    justify-content: center;
}
.site-dev span{
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.site-dev_cont{
        gap: 10px;
    display: flex;
}
.site-dev_text{
    display: flex;
    align-items: center;
}

.news-block__owlcarousel .news-block-item {
    width: 95%;
}


@media(max-width: 1024px){
    .dealers-filter-mob{
    gap: 10px;
    flex-direction: column;
    }
}

.bestsellers-item__chars{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.owl-wrapper{
    padding: 15px 0;
}

.owl-item{
    display: flex !important;
    justify-content: center !important;
}

.select2-resource|parent_0-result-vwir-52{
    display: none;
}

.slct_dropdown{
    height: 200px !important;
    overflow: scroll !important;
}
.header-n-nav__link.active .nav-link__sub {
  opacity: 1;
  pointer-events: all;
    border-radius: 5px;
    border: 1px solid #d9d3d3;
}

.nav-link__sub {
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;
  background: white;
  padding: 20px 0 20px 0;
  position: absolute;
  top: 90px;
  z-index: 999;
  width: 150px;
  text-align: center;
}

.nav-link-sub__link {
  width: 80%;
  line-height: 1.1;
  margin: 10px auto;
  border-bottom: 3px #fff solid;
  text-align: left;
    display: block;
}

.nav-link-sub__link.header-n-nav__link {
    display: block;
    text-align: left;
}

.nav-link-sub__link:hover {
  border-bottom: 3px #FF0A14 solid;
}

.header-n a.header-n__nav-active, .header-n-nav__link.header-n__nav-active > a {
    color: #FF0A14;
}

.banner .owl-pagination{
    top: -100px !important;
    position: relative !important;
    width: 50% !important;
}

.page-table{
    overflow-x: scroll;
}
.header-n-btn__btn {
  color: white !important;
}

.search-mobile__block .header-n-search__input {
  width: 100%;
}

.catalog a {
  color: black;
}

.header-n a {
  color: black;
}

.bestsellers a {
  color: black;
}

.news-block a {
  color: black;
}

.footer-n a {
  color: white;
}

.footer-n {
  color: white;
  padding-top: 60px;
  background: #20324C;
  margin: 0;
  height: auto;
  position: static;
}

.footer-n__container {
  display: flex;
  justify-content: space-between;
}

.footer-n__social {
  width: 221px;
  border-top: 1px solid rgba(255, 255, 255, 0.1019607843);
    padding-top: 15px;
    margin-top: 10px;
}

.footer-n__navs {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  margin-bottom: 54px;
  gap: 10px;
}

.footer-n__copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.1019607843);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1019607843);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  gap: 29px;
  color: #8C9CB3;
}

.footer-n-social__vk {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1019607843);
  transition: all 0.2s ease-in-out;
}

.footer-n-social__vk:hover {
  background: #ffffff38;
}

.footer-n-nav__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.footer-n-nav__link {
  margin: 3px 0;
  transition: all 0.2s ease-in-out;
  font-weight: 300;
}

.footer-n-nav__link:hover {
  color: #FF0A14;
}

.footer-n-copyright__policy {
  transition: all 0.2s ease-in-out;
}

.footer-n-copyright__policy:hover {
  color: white;
}

.header-n {
  width: auto;
  z-index: 99999;
  height: 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0509803922);
  padding: 0;
  margin: 0;
}

.header-n__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 35px;
}

.header-n__logo {
  flex-shrink: 0;
}

.header-n__search {
  width: 250px;
  height: 44px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background: #F9F9F9;
  border: rgba(0, 0, 0, 0.0509803922) solid 1px;
  flex-shrink: 0;
}

.header-n__nav {
  background: white;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-n__btn {
  width: 143px;
  height: 44px;
}

.header-n__btn-mobile {
    margin-top: 20px;
  width: 143px;
  height: 44px;
}

.header-n__social {
  //padding-left: 35px;
  //border-left: 1px solid rgba(0, 0, 0, 0.0509803922);
  height: 100%;
  display: flex;
  align-items: center;
}

.header-n-search__input {
  height: 100%;
  color: #8C9CB3;
  background: none;
  border: none;
}

.header-n-nav__link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px transparent solid;
  transition: all 0.2s ease-in;
}

.header-n-nav__link:hover {
  border-bottom: 3px #FF0A14 solid;
}

.header-n-btn__btn {
  font-weight: 700;
}

.header-n-btn__btn-mobile {
  display: block;
}

.header-n-social__vk {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00000024;
  transition: all 0.1s ease-in;
}

.header-n-social__vk:hover {
  background: #e2e2e2;
}

.header-n__nav-mobile {
  display: none;
}

.header-n__nav-mobile .nav-mobile__btn-close {
  display: none;
}

.header-n__nav-mobile.active .nav-mobile__btn-close {
  display: block;
}

.header-n__nav-mobile.active .nav-mobile__btn-open {
  display: none;
}

.header-n__nav-mobile.active .nav-mobile__nav {
  right: 0px;
}

.nav-mobile__btn {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.0509803922);
  transition: all 0.1s ease-in;
}

.nav-mobile__btn:hover {
  background: #e2e2e2;
}

.nav-mobile__nav {
  position: absolute;
  top: 60px;
  right: 100%;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  z-index: 999;
  background: #ffffff;
  transition: all 0.2s ease-in-out;
  padding: 20px;
}

.header-n__search-mobile {
  margin-left: auto;
}

.header-n-nav-mobile__link {
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin: 15px 0 0 0;
}

.search-mobile {
  display: none;
  width: 90%;
}

.search-mobile .header-search__input {
  width: 100%;
}

.search-mobile__btn {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.0509803922);
  transition: all 0.1s ease-in;
}

.search-mobile__btn:hover {
  background: #e2e2e2;
}

.search-mobile__block {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 136px;
  position: absolute;
  top: -250%;
  left: 0;
  z-index: 999;
  background: white;
}

.search-mobile__block.active {
  top: 0;
}

.search-mobile {
  margin: 0 auto;
  width: 95%;
  height: 44px;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background: #F9F9F9;
  border: rgba(0, 0, 0, 0.0509803922) solid 1px;
  flex-shrink: 0;
  z-index: 1000;
  display: none;
}

.search-mobile__close {
  margin: 20px;
  display: flex;
  justify-content: flex-end;
}

.header-n__search-mobile {
  display: none;
}

.banner .owl-dots {
  top: -90px !important;
  position: relative !important;
  width: 50% !important;
}
.banner__items {
    .owl-wrapper {
        padding: 0 !important;
    }
}
.banner-items__item {
  width: 100%;
  min-height: 600px;
  @media (max-width: 1460px) {
        min-height: 500px;
    }
    @media (max-width: 1020px) {
        min-height: 450px;
    }
    & a{
        height: 100%;
        & img{
             width: 100%;
            height: 100%;
            object-fit: cover;
        }    
    }
    
}

.banner-item {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 40px;
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        @media (max-width: 620px) {
            flex-direction: column;
            justify-content: start;
            align-items: center;
        }
    }
    &__wrapper {
        width: 45%;
        @media (max-width: 620px) {
            width: 100%;
        }
    }
    &__image {
        width: 45%;
        object-fit: contain;
        @media (max-width: 1210px) {
            width: 35%;
        }
        @media (max-width: 620px) {
            max-width: 520px;
            width: 90%;
            margin-top: 20px;
        }
    }
    &__title {
        display: block !important;
        font-family: DrukCyr, "Open Sans", sans-serif !important;
        font-size: 90px !important;
        color: #fff !important;
        font-weight: 700 !important;
        line-height: 100% !important;
        letter-spacing: 0.03em !important;
        text-transform: uppercase !important;
        margin-top: 0 !important;
        margin-bottom: 30px !important;
        @media (max-width: 1460px) {
            font-size: 60px !important;
        }
        @media (max-width: 1020px) {
            font-size: 46px !important;
        }
        @media (max-width: 620px) {
            margin-bottom: 20px !important;
        }
    }
    &__description {
        display: block;
        color: #FFF;
        font-family: "PT Sans", sans-serif !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: 30px;
        @media (max-width: 620px) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
}

.banner-advantage {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: max-content;
    background: rgba(255, 255, 255, 0.09);
    padding: 10px 15px 15px 15px;
    &__icon {
        width: 24px;
        height: 24px;
    }
    &__title {
        color: #FFF;
        font-family: "PT Sans", sans-serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        text-transform: uppercase;
        @media (max-width: 620px) {
            font-size: 12px;
        }
    }
}

.banner-item__container {
  width: 100%;
}

.banner-item__text {
  font-weight: 300;
  color: #fff;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.banner-item__text-header {
  font-family: sans-serif;
  font-size: 90px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.banner-item__text-desc {
  font-size: 18px;
}

.banner-item__text-char {
  width: 152px;
  height: 86px;
  padding: 10px 0 0 15px;
  gap: 5px;
  background: rgba(255, 255, 255, 0.0901960784);
}

.text-char__text {
  text-transform: uppercase;
  line-height: 20.8px;
}

.text-char__text span {
  font-weight: 700;
}

.catalog a {
  transition: all 0.2s ease-in-out;
}

.catalog a:hover {
  color: #c70e14;
}

.catalog__top {
  display: flex;
  justify-content: space-between;
}

.catalog__header {
  margin-bottom: 30px;
}

.catalog-items__item {
  display: flex;
  width: 467px;
  height: 320px;
  border: 1px solid rgba(0, 0, 0, 0.0509803922);
}

.catalog-item__img {
  padding: 20px;
  width: 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.0509803922);
  display: flex;
  align-items: center;
}

.catalog-item__img-img {
  object-fit: contain;
}

.catalog-item__content {
  padding: 20px 25px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.catalog-item__header {
  margin-bottom: 8px;
}

.catalog-item__links-link {
    display: inline;
}

.about {
  padding: 50px 0;
  background: url(/assets/templates/img/about.jpg) 0 0 no-repeat;
  background-position: center center;
  background-size: cover;
}

.about__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about__header {
  color: #fff;
}

.about__text {
  color: #fff;
  width: 50%;
  font-weight: 300;
}

.bestsellers {
  padding: 50px 0;
}

.bestsellers__top {
  display: flex;
  justify-content: space-between;
}

.bestsellers__items {
  margin-top: 30px;
}

.bestsellers-items__item {
  width: 270px;
  border: 1px solid rgba(0, 0, 0, 0.0509803922);
  transition: all 0.2s ease-in-out;
  background: white;
}

.bestsellers-items__item:hover {
  transform: scale(1.05);
}

.bestsellers-item__img {
  padding: 8px 34px;
  border-right: 1px solid rgba(0, 0, 0, 0.0509803922);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0509803922);
}

.bestsellers-item__img-img {
  width: 100%;
    object-fit: cover;
}

.bestsellers-item__content {
  padding: 15px 20px;
}

.bestsellers-item__name {
  margin-bottom: 10px;
}

.bestsellers-item__chars-item {
  font-size: 14px;
}

.bestsellers-item__chars-item span {
  color: #8C9CB3;
}

.news-block__top {
  display: flex;
  justify-content: space-between;
}

.news-block {
    padding: 50px 0;
    background: #F9F9F9;
}

.news-block__items {
  margin-top: 30px;
}

.news-block-items__item:hover .news-block-item__name {
    color: #FF0A14;
}

.news-block-item{
    color: inherit;
}

.news-block-item__date {
  font-size: 12px;
  color: #8C9CB3;
  margin-bottom: 5px;
}

.news-block-item__name {
  transition: all 0.2s ease-in-out;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0509803922);
}

.news-block-item__desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.faq {
  padding: 50px 0;
}

.faq__top {
  display: flex;
  justify-content: space-between;
}

.faq__items {
  display: flex;
  flex-direction: column;
}

.faq-item__title {
  cursor: pointer;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0509803922);
  transition: all 0.4 ease-in;
  margin: 25px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.faq-item__text {
  overflow: hidden;
  max-height: 0px;
  transition: all 1s ease-out;
}

.faq-item__text a {
  display: inline;
  color: #36CA78;
  text-decoration: underline !important;
}

.faq-item__text a:hover {
  color: #283951;
}

.faq-item.active .faq-item__title {
  border-bottom: 1px solid #FF0A14;
}

.faq-item.active .faq-item__text {
  max-height: 500px;
}

.faq-item.active .sign-line-1 {
  transform: rotate(90deg);
}

.sign-line-1 {
  transform: rotate(0deg);
}

.sign-line {
  position: absolute;
  width: 14px;
  height: 2px;
  background: #20324C;
  transition: all 0.5s ease-in-out;
}

.faq-item__title-sign {
  position: relative;
  left: -20px;
  top: 10px;
}

.contacts-h {
  padding-bottom: 50px;
}

.contacts-h__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.contacts-h__offices {
  background: #F9F9F9;
  padding: 50px 50px 50px 50px;
}

.contacts-h__service {
  background: #F9F9F9;
  padding: 50px 50px 50px 50px;
  display: flex;
  flex-direction: column;
}

.contacts-h-offices__header {
  margin-bottom: 30px;
}

.contacts-h-offices__items {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
}
.contacts-h-item {
    &:not(:last-child) {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
}
.contacts-h-item__header {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.contacts-h-item__address {
  gap: 10px;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.contacts-h-item__address:before {
  content: url(/assets/templates/img/address.svg);
}

.contacts-h-item__address a {
  display: inline;
  color: #FF0A14;
  text-decoration: underline !important;
}

.contacts-h-item__address a:hover {
  color: #283951;
}
.contacts-h-item__phone a, .contacts-h-item__fax a {
    color: #283951;
}
.contacts-h-item__phone {
  gap: 10px;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.contacts-h-item__phone:before {
  content: url(/assets/templates/img/call.svg);
}

.contacts-h-item__fax {
  gap: 10px;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.contacts-h-item__fax:before {
  content: url(/assets/templates/img/fax.svg);
}

.contacts-h-service__header {
  margin-bottom: 30px;
}

.contacts-h-service__items {
  display: flex;
  flex-direction: column;
}

.contacts-h-service__btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: auto;
  gap: 20px;
}

.contacts-h-service__btns-services {
  margin-top: 20px;
  width: 296.5px;
  height: 44px;
}

.contacts-h-service__btns-services:hover {
  color: #95060b !important;
  border: 1px solid #95060b;
}

.contacts-h-service__btns-callback {
  margin-top: 20px;
  width: 296.5px;
  height: 44px;
  border: 1px solid #FF0A14;
}
.contacts-h-service__btns .contacts-h-service__btns-callback {
    width: 100%;
}
section.catalog {
    padding: 50px 0;
}
.shops {
  padding: 50px 0;
  background: #F9F9F9;
  &--content {
        padding: 0 !important;
        margin-bottom: 20px;
        background: #fff;
    }
}

.shops__top {
  display: flex;
  justify-content: space-between;
}

.shops__header {
  margin-bottom: 30px;
}

.shops__items {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  &--slider {
      & .shops-items__item {
          width: 100%;
          height: auto;
      }
  }
}

.shops-items__item {
  width: 221px;
  height: 150px;
  background: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.shops-item__img {
  object-fit: contain;
  max-width: 100%;
}

@media (max-width: 1460px) {
  .header-n__container {
    gap: 10px;
  }

  .header-n-nav__link {
    font-size: 14px;
  }

  .catalog-items__item {
    width: 582px;
  }
}

@media (max-width: 1439px) {
  .header-n__search-mobile {
    display: flex;
  }

  .search-mobile {
    display: flex;
  }

  .header-n__btn {
    display: none !important;
  }

  .header-n-btn-mobile {
    display: block !important;
  }

  .header-n__search {
    display: none;
  }

  .header-n__social {
    padding: 0;
    border: none;
  }

  .header-n__nav {
    justify-content: space-around;
  }

  .catalog-items__item {
    width: 500px;
  }
  .contacts-h-item {
    &:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
  }
  .contacts-h-item {
    &:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
  }
}

@media (max-width: 1200px) {
  .bestsellers-items__item {
    width: 325px;
  }

  .contacts-h-service__btns {
      grid-template-columns: 1fr;
    }

  .contacts-h-service__btns-callback {
    margin-top: 0;
  }
  .lb-outerContainer {
    width: 400px !important;
    height: auto !important;
    min-height: 270px !important;
}
}

@media (max-width: 1023px) {
  .wrapper {
    padding-top: 60px;
  }

  .header-n {
    height: 60px;
    background: white;
    position: fixed;
    width: 100%;
  }

  .header-n__logo img {
    width: 107px;
  }

  .header-n__container {
    justify-content: start;
  }

  .header-n__nav {
    display: none;
  }

  .header-n__nav-mobile {
    display: block;
  }

  .catalog-items__item {
    height: 100%;
    width: 320px;
    flex-direction: column;
    background: white;
  }

  .catalog-item__img {
    width: 100%;
  }

  .catalog-item__content {
    width: 100%;
  }

  .bestsellers-items__item {
    width: 350px;
  }

  .contacts-h__service {
    padding: 50px;
  }

  .contacts-h__offices {
    padding: 50px;
  }

  .contacts-h__content {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .contacts-h__offices {
    padding: 35px 20px 40px 20px;
  }

.contacts-h__service {
    padding: 35px 20px 40px 20px;
  }
  .contacts-h-service__btns {
      margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .shops-item{
    width: 135px;
    padding: 10px;
    height: 95px;
}
    .contacts-h-service__btns {
      grid-template-columns: 1fr;
    }

    .contacts-h-service__btns-callback{
        width: 100% !important;
    }
  .banner-item__text-header {
    font-size: 54px !important;
    line-height: 50px !important;
  }

  .banner-item__text-desc {
    font-size: 15px !important;
  }


  .banner-item__text {
    margin-top: 36px;
  }
  
  .lb-outerContainer {
    width: 400px !important;
    height: auto !important;
    min-height: 250px !important;
}


  .catalog {
    padding: 20px 0 !important;
  }

  .catalog__top {
    margin-bottom: 30px;
    flex-direction: column;
  }

  .catalog__header {
    margin-bottom: 0;
  }

  .about {
    background: url(/assets/templates/img/about-mobile.jpg) 0 0 no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 20px 0 264px 0;
  }

  .about__text {
    width: 100%;
  }

  .bestsellers {
    padding: 20px 0 !important;
  }

  .bestsellers__top {
    margin-bottom: 30px;
    flex-direction: column;
  }
  section.catalog {
    padding: 20px 0 !important;
  }
  .shops {
    padding: 20px 0 !important;
  }

  .shops__top {
    margin-bottom: 30px;
    flex-direction: column;
  }

  .shops__header {
    margin-bottom: 30px;
  }

  .bestsellers-items__item {
    width: 288px;
  }

  .news-block {
    padding: 20px 0 !important;
  }

  .news-block__top {
    margin-bottom: 30px;
    flex-direction: column;
  }

  .faq {
    padding: 20px 0 !important;
  }

  .faq__top {
    margin-bottom: 30px;
    flex-direction: column;
  }

  .footer-n__navs {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footer-n__container {
    flex-direction: column;
    gap: 43px;
  }

  .footer-n__copyright {
    padding: 15px;
    align-items: flex-start;
    gap: 5px;
    flex-direction: column;
  }
}

@media (max-width: 500px) {
    .lb-outerContainer {
    width: 320px !important;
    height: auto !important;
    min-height: 200px !important;
}
}

.section-header {
  font-family: DrukCyr, OpenSans, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-top: 0;
}

.item-header {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-more {
  display: flex !important;
  align-items: center;
  gap: 11.33px;
  transition: all 0.1s ease-in;
}

.btn-more svg {
  fill: #FF0A14;
}

.btn-more p {
  color: #FF0A14;
  font-weight: 700;
  margin: 0;
}

.btn-more:hover {
  cursor: pointer;
}

.btn-more:hover svg {
  fill: #c70e14;
}

.btn-more:hover p {
  color: #c70e14;
}

.btn {
  background: #FF0A14;
  color: white;
  font-family: "PT Sans", sans-serif !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: all 0.1s ease-in;
  font-size: 14px;
  border: 1px solid #FF0A14;
}

.btn:hover {
  background: #9f080d;
  border: 1px solid #9f080d;
}

.btn-secondary {
  background: transparent;
  color: #FF0A14;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: all 0.1s ease-in;
  font-size: 14px;
  border: 1px solid #FF0A14;
}

.btn-secondary:hover {
  background: #FF0A14;
  border: 1px solid #FF0A14;
  color: #fff;
}

.container-home {
  max-width: 1449px;
  min-width: 1449px;
  margin: 0 auto;
}

@media (max-width: 1460px) {
  .container-home {
    max-width: 1200px;
    min-width: 1200px;
  }
}

@media (max-width: 992px) {
  .container-home {
    max-width: 750px;
    min-width: 750px;
  }
}

@media (max-width: 767px) {
  .container-home {
    max-width: none !important;
    min-width: auto;
    width: 100%;
    padding: 0 15px;
  }
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.wrapper>main {
  flex: 1 1 auto;
}

.wrapper>*:not(.container-home) {
  min-width: 0;
}

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  height: 100%;
}

body {
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  color: inherit;
  display: block;
}

a:link,
a:visited {
  //text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

@font-face {
  font-family: DrukCyr;
  font-display: swap;
  src: url("/assets/templates/fonts/drukcyr-bold.woff2") format("woff2"), url("/assets/templates/fonts/drukcyr-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.checkbox {
    --checkbox-color: #1387F2;
    position: relative;
    display: inline-flex;
    margin: 0;
    user-select: none;
    font-size: 0;

    &:hover {
        .checkbox {
            &__text {
                &::before {
                    border-color: var(--checkbox-color);
                }
            }
        }
    }
    &__input {
        display: none;

        &:checked + .checkbox {
            &__text {
                &::before {
                    background-color: var(--checkbox-color);
                    border-color: var(--checkbox-color);
                    background-image: url('/assets/templates/img/ico-check.svg');
                }
            }
        }

        &:disabled + .checkbox {
            &__text {
                color: #E5E5E5;
                &::before {
                    border-color: #E5E5E5;
                    cursor: default;
                }
            }
        }

        &:checked:disabled + .checkbox {
            &__text {
                color: #E5E5E5;
                &::before {
                    background-color: #F8F9FB;
                    border-color: #E5E5E5;
                    background-image: url('/assets/templates/img/ico-check-disabled.svg');
                }
            }
        }
    }

    &__text {
        position: relative;
        display: inline-flex;
        align-items: flex-start;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.55;
        letter-spacing: 0.01em;
        color: #676767;

        @include mediaLaptop {
            font-size: 12px;
        }

        &::before {
            content: "";
            display: block;
            background-color: #F8F9FB;
            border: 1px solid #E5E5E5;
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 15px;
            cursor: pointer;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px;
            transition: all ease-out 0.3s;

            @include mediaPhone {
                margin-right: 10px;
            }
        }

        & > span {
            margin-top: 3px;
        }

        a {
            color: #222222;
            text-decoration: underline;
        }
    }
}

.radio {
    position: relative;
    display: inline-block;
    margin: 0;
    user-select: none;
    font-size: 0;

    &:hover {
        .radio {
            &__text {
                &::before {
                    border-color: #1387F2;
                }
            }
        }
    }

    &__input {
        display: none;

        &:checked + .radio {
            &__text {
                &::before {
                    background-color: #1387F2;
                    border-color: #1387F2;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
                }
            }
        }

        &:disabled + .radio {
            &__text {
                color: #E5E5E5;
                &::before {
                    border-color: #E5E5E5;
                    cursor: default;
                }
            }
        }

        &:checked:disabled + .radio {
            &__text {
                color: #E5E5E5;
                &::before {
                    background-color: #F8F9FB;
                    border-color: #E5E5E5;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
                }
            }
        }
    }

    &__text {
        position: relative;
        display: inline-flex;
        align-items: flex-start;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.55;
        letter-spacing: 0.01em;
        color: #676767;

        @include mediaLaptop {
            font-size: 12px;
        }

        &::before {
            content: "";
            display: block;
            background-color: #F8F9FB;
            border: 1px solid #E5E5E5;
            border-radius: 50%;
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 15px;
            cursor: pointer;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
            transition: all ease-out 0.3s;
            @include mediaPhone {
                margin-right: 10px;
            }
        }

        & > span {
            margin-top: 3px;
        }

        a {
            color: #222222;
            text-decoration: underline;
        }
    }
}

.map-filter {
    & * {
        @include scrollbar(#20324C, rgba(255, 0, 0, 0), 5px, 5px)
    }
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include media(768px) {
        flex-direction: column;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        @include media(768px) {
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: 20px;
        }
        & .map-filter-list {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    &-sidebar {
        display: none;
        min-width: 305px;
        max-width: 305px;
        height: 700px;
        overflow: hidden;
        margin-right: 25px;
        padding: 20px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        @include media(1300px) {
            min-width: 250px;
            max-width: 250px;
            height: 550px;
        }
        @include media(768px) {
            min-width: auto;
            max-width: none;
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
        .select2 {
            margin-bottom: 10px;
        }
        &__listing {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: 100%;
        }
        &__item {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
        }
        & .select2-container {
            width: 100% !important;
        }
    }
    &__content {
        width: 100%;
        //height: 700px;
        overflow: hidden;
        @include media(1300px) {
            //height: 550px;
        }
    }
    &-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 15px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            &.--distributor .checkbox {
                --checkbox-color: #56CC61;
            }
            &.--integrator .checkbox {
                --checkbox-color: #F2B313;
            }
            &.--fereral .checkbox {
                --checkbox-color: #CC56AB;
            }
            & .checkbox {
                &__text {
                    color: var(--checkbox-color);
                }
            }
        }
    }
    &__map-block {
        height: 600px;
        background: rgba(0, 0, 0, 0.05);
        @include media(1600px) {
            height: 400px;
        }
        @include media(768px) {
            height: 300px;
        }
    }
}
.partner-item {
    &__title {
        display: flex;
        align-items: center;
        color: #323232;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 5px;
        transition: all 0.3s;
        @include media(1300px) {
            font-size: 16px;
        }
        &:before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #1387F2;
            border-radius: 50%;
            margin-right: 10px;
        }
        &[data-go-coords] {
            cursor: pointer;
            &:hover {
                color: #1387F2;
            }
        }
    }
}
.tabs {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    &__item {
        position: relative;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
        color: #283951;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        cursor: pointer;
        transition: all 0.3s;
        &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 0%;
            height: 3px;
            background: #283951;
            transition: all 0.3s;
        }
        &:not(:last-child) {
            margin-right: 25px;
        }
        &.active {
            color: #FF0A14;
            &:after {
                width: 100%;
                background: #FF0A14;
            }
        }
    }
}
.tab-content {
    display: none;
    width: 100%;
    
    & iframe {
        width: 720px;
        height: 400px;
        
        @include media(768px) {
            width: 400px;
            height: 260px;
        }
        
        @include media(460px) {
            width: 280px;
            height: 200px;
        }
    }
    
    @include media(520px) {
        table {
            width: 100% !important;
        }
        table th, table td {
            width: auto !important;
            min-width: auto !important;
        }
    }
    &.active {
        display: block;
    }
    .table-wrapper {
        margin-top: 40px;
    }
}
.grid-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    @include media(768px) {
        grid-template-columns: 1fr;
    }
    &--alt {
        margin-top: 20px;
        p {
            margin-bottom: 0;
        }
    }
}
.services-map {
    margin-top: 40px;
    width: 100%;
    height: 700px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.05);
    @include media(1700px) {
        height: 500px;
    }
    @include media(1300px) {
        height: 400px;
    }
    @include media(768px) {
        height: 250px;
    }
}
.table-wrapper {
    overflow: auto;
    position: relative;
    @include media(768px) {
        max-width: 600px;
    }
    @include media(620px) {
        max-width: 400px;
    }
    @include media(420px) {
        max-width: 300px;
    }
    & table {
    	vertical-align: top;
    	max-width: 100%;
    	overflow-x: auto;
    }
}
table {
    background: rgba(0, 0, 0, 0.05);
    & th, & td {
        &:nth-child(1) {
            width: 25%;
        }
        &:nth-child(2) {
            width: 25%;
        }
        &:nth-child(3) {
            width: 25%;
        }
        &:nth-child(4) {
            width: 25%;
        }
    }
    & thead {
        tr {
            & th {
                background: #283951;
                color: #fff;
                padding: 11px 15px;
                text-align: start;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                @include media(1700px) {
                    padding: 8px 12px;
                    font-size: 14px;
                }
                @include media(768px) {
                    padding: 6px 8px;
                    font-size: 12px;
                }
            }
        }
    }
    & tbody {
        tr {
            & td {
                background: #fff;
                padding: 11px 15px;
                text-align: start;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                min-width: 130px;

                @include media(1700px) {
                    padding: 8px 12px;
                    font-size: 14px;
                }
                @include media(768px) {
                    padding: 6px 8px;
                    font-size: 12px;
                }
            }
            &:nth-child(even) {
                & td {
                    background: #F9F9F9;
                }
            }
        }
    }
}