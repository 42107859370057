.product {
    display: flex;
    align-items: start;
    gap: 50px;
    @include media(1460px) {
        gap: 20px;     
    }
    @include media(992px) {
        flex-direction: column;
    }
    &-specifications {
        table tr td {
            width: 50% !important;
        }
    }
    &-info {
        &__wrapper {
            display: flex;
            align-items: start;
            gap: 25px;
            margin-bottom: 40px;
            @include media(1460px) {
                flex-direction: column;
            }
            @include media(520px) {
                margin-bottom: 0;  
            }
            & > * {
                width: 50%;
                @include media(1460px) {
                    width: 100%;
                }
            }
        }
        & .comparison {
            margin-bottom: 20px;
            @include media(520px) {
                margin-bottom: 0;
            }
        }
    }
    &-content {
        &__title {
            margin-bottom: 20px;
            line-height: 150% !important;
            margin-top: -14px;
        }
        &__advantages {
            margin-bottom: 20px;
        }
    }
    & .tabs {
        @include media(520px) {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
        }
        &__item {
            @include media(520px) {
                margin-right: 0;
                &:after {
                    content: none;
                }
            }
        }
        .tab-btn {
            padding: 10px 25px;
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &:hover, &.active {
                background: #9f080d;
                border: 1px solid #9f080d;
            }
        }
    }
    & .tab-content {
        margin-bottom: 30px;
    }
    &-gallery {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 40px 70px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            cursor: pointer;
        }
        .carusel {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 10px;
            & .thumbnails {
                display: flex;
                align-items: center;
                width: 100% !important;
                height: auto !important;
                gap: 20px;
                @include media(992px) {
                    gap: 10px;
                }
                & a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    max-height: 113px;
                    & img {
                        margin: 0 !important;
                    }
                }
            }
            & .b-l2, .b-r2 {
                height: 44px;
                width: 44px;
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(0, 0, 0, 0.05);
                background: none;
            }
        }
    }
    &-sidebar {
        display: flex;
        flex-direction: column;
        min-width: 467px;
        max-width: 467px;
        gap: 30px;
        margin-bottom: 30px;
        @include media(1460px) {
            min-width: 367px;
            max-width: 367px;
            gap: 20px;
            margin-bottom: 20px;
        }
        @include media(992px) {
            min-width: auto;
            max-width: none;
            width: 100%;
        }
        & .comparison {
            text-align: end;
            font-weight: 700;
        }
    }
    &-advantages {
        &__item {
            display: flex;
            align-items: center;
            & img {
                width: 68px;
                height: 68px;
                object-fit: contain;
                padding: 14px;
                border: 1px solid rgba(0, 0, 0, 0.05);
                background: #fff;
                margin-right: 20px;
                @include media(1460px) {
                    width: 40px;
                    height: 40px;
                    padding: 7px;
                    margin-right: 15px;
                }
            }
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            & span {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                @include media(1460px) {
                    font-size: 14px;
                }
            }
        }
    }
    &-form {
        display: flex;
        flex-direction: column;
        &__title {
            display: block;
            font-family: DrukCyr, OpenSans, sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: 0.84px;
            text-transform: uppercase;
            margin-bottom: 5px;
            @include media(1460px) {
                font-size: 22px;
            }
        }
        &__subtitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 25px;
            @include media(1460px) {
                margin-bottom: 15px;
            }
            @include media(1460px) {
                font-size: 14px;
            }
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            @include media(1460px) {
                margin-bottom: 15px;
            }
            & > * {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
            & textarea {
                @include media(1460px) {
                    height: 70px;
                }
            }
        }
        & .checkbox {
            --checkbox-color: #FF0A14;
            @include media(1460px) {
            }
            &__text {
                &:before {
                    margin-right: 10px;
                }
                & a {
                    color: #36ca78;
                    text-decoration: none;
                }
            }
        }
        & .btn {
            width: fit-content;
            padding: 10px 25px;
        }
    }
}