@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400italic,700,700italic&subset=latin,cyrillic);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);

.slide-down{
    transition: all 0.4s;
}

.slide-down:hover{
    color: #FF0A14;
}

.slide-up{
    cursor: pointer;
}

* {margin: 0;padding: 0;}
html {-webkit-text-size-adjust: none;}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {display: block;}

body {font: 14px/24px 'PT Sans', sans-serif;width: 100%;height: 100%;color:#323232;overflow-x:hidden;}

a{color: #36ca78;text-decoration: none;}
a:hover{text-decoration:none;}
a img{border:none;outline:none;}
h1 {
    margin-top: 0;
}
p{margin:0 0 20px 0;}
p:last-child {
    margin: 0;
}

fieldset {border:none;}

input, textarea{box-shadow: none;-webkit-box-shadow: none;-webkit-border-radius: 0px;-moz-border-radius: 0px;border-radius: 0px;}

input, textarea, select{outline:none;-moz-box-shadow: none;-webkit-box-shadow: none;box-shadow: none;}
input[type="submit"]:hover, input[type="submit"]:focus{cursor:pointer;outline:none;}
input[type="text"], textarea{font-family:'PT Sans', sans-serif!important;box-shadow:none!important;-moz-box-shadow:none!important;-webkit-box-shadow:none!important;border-radius:none!important;-moz-border-radius:none!important;-webkit-border-radius:none!important;}
input[type="text"]:focus, textarea:focus{border:none;outline: none;box-shadow:none!important;-moz-box-shadow:none!important;-webkit-box-shadow:none!important;border-radius:none!important;-moz-border-radius:none!important;-webkit-border-radius:none!important;}

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder { color:#8c9cb3!important; opacity: 1; }
input:-moz-placeholder, textarea:-moz-placeholder, input:focus:-moz-placeholder, textarea:focus:-moz-placeholder, input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder, input:-ms-input-placeholder, input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {color:#8c9cb3!important;}

/* @crossbrowser placeholder styling :: modern browsers only IE10+*/
input[type=search] {-webkit-appearance: textfield;}
question_ask::-webkit-search-decoration, question_ask::-webkit-search-cancel-button {-webkit-appearance: none;}
input:invalid {-moz-box-shadow: none; box-shadow: none;}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {color:#8c9cb3;}
input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {color:#8c9cb3;}
input:-moz-placeholder, textarea:-moz-placeholder {color:#8c9cb3;}
input:focus:-moz-placeholder, textarea:focus:-moz-placeholder {color:#8c9cb3;}
input::-moz-placeholder, textarea::-moz-placeholder {color:#8c9cb3;opacity: 1;}
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {color:#8c9cb3;opacity: 1;}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {color:#8c9cb3;}
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {color:#8c9cb3;}

/*Scroll Safari&Chrome*/
::-webkit-scrollbar{width:9px;}
::-webkit-scrollbar-track{border-width:1px;border-color: #1b2a3f;border-style:solid;background-color: #1c2c42;}
::-webkit-scrollbar-thumb{background: url(../img/scrollbar-thumb.png) no-repeat;height:60px;width:11px}

.h2{font-size:22px;text-transform:uppercase;margin-bottom:30px;margin-top:0;line-height:1.4em;color:#283951;}
h2, h3, h4, .h3{margin:20px 0;text-transform:uppercase;line-height:1.4em;}
h2{font-size:18px;}
h3, .h3{font-size:16px;}
h4{font-size:14px;}
.h3 a{text-decoration:none;color:#323232;}

table{width:100%}

.clear{clear:both;}
.fleft{float:left;}
.fright{float:right;}
.bold{font-weight:bold;}    
.italic{font-style:italic;}
.center{text-align:center;}
.red, .requiredlist, .errors strong{color:#ed1c24}

.time{text-transform:uppercase;font-size:14px;margin-bottom:30px;display:block;color:#878788;}

/*BACK TO TOP*/
#backtop {cursor:pointer;position:fixed;display:none;z-index:1000;background: url(../img/top.png) no-repeat;width:33px;height:33px;bottom:30px;right:20px;}

.wrapper {min-height: 100%;height: auto !important;height: 100%;width:100%;}

.top_block{position:absolute;right:0;top:0;z-index:1002;width:230px;}
#adress{position:absolute;right:0;top:0;z-index:1001;width:230px;}
#adress .moskow, #adress .piter{background:#20324c;}
#adress .moskow{height:85px;}
#adress .moskow .city:after{content:'';background: url(../img/arrow_down_hover.png) no-repeat;width:14px;height:14px;display: block;left: 80%;position: absolute;z-index:1001;top:-5px}
#adress .menu-open .city:after {-webkit-transform: rotate(180deg); /* Chrome y Safari */-moz-transform: rotate(180deg); /* Firefox */filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */-o-transform: rotate(180deg); /* Opera */}
#adress .moskow span, #adress .piter span{padding:0 0 0 20px;}
#adress .moskow .city:hover{cursor:pointer;}
#adress .piter{height:89px;box-shadow:0 2px 3px #1c2a40 inset;-moz-box-shadow:0 2px 3px #1c2a40 inset;-webkit-box-shadow:0 2px 3px #1c2a40 inset;}
#adress .city{font-size:16px;color:#fff;display:inline-block;line-height:11px;margin-top:30px;width:100%;position:relative;}
.street{font-size:13px;color:#7d8da3;display:block;font-style:italic;line-height:11px;}

.where_buy{position:absolute;right:0;top:85px;height:89px;background:#283951;z-index:1000;width:230px;}
.where_buy.active{background:#ed1c24;}
.where_buy a{font-size:16px;color:#fff;display:block;margin:0 25px 0 20px;text-decoration:none;background: url(../img/location.png) right center no-repeat;line-height:5.5em}
.where_buy.active a{background: url(../img/location_active.png) right center no-repeat;pointer-events: none;}

/* Header*/
header {height:95px;padding:0;width: 980px;margin: 0 auto;}
.logo{padding:5px 0 0 0;float:left}
.phone{background: url(../img/tel_i.png) no-repeat left 18%;font-size:14px;font-weight:bold;color:#000;float:left;padding-left:20px;margin-top:25px;margin-left:90px;line-height:1.5em;}
.search-form{float:left;margin-left:80px;margin-top:30px;position:relative;/*overflow:hidden;*/width:230px;border-bottom:1px solid #b1b1b1;padding-bottom:10px}
.search-form input[type="submit"]{border:none;text-indent:-9999em;background: url(../img/search_i.png) no-repeat center center;width:15px;height:16px;float:right}
.search-form input[type="text"]{border:none;color:#b1b1b1;font-size:16px;font-weight:bold;text-transform:uppercase;/*position:absolute;display:block;left:-230px;width:90%;-moz-transition:all 0.1s ease-out;-webkit-transition:all 0.1s ease-out;-o-transition:all 0.1s ease-out;transition:all 0.1s ease-out;-moz-transition-delay:0.15s;-webkit-transition-delay:0.15s;-o-transition-delay:0.15s;transition-delay:0.15s;*/}
/*.search-form:hover input[type="text"]{left:0}
.search-form:hover{border-bottom:1px solid #b1b1b1;padding-bottom:10px}*/
/*Search Page*/
#search_text{width:450px;overflow:hidden;display:block;}
#search_text input[type="text"]{width:300px!important;float:left;margin:0!important;padding:7px 15px 6px!important;}
#search_text input[type="submit"]{border:none;background:#ed1c24;color:#fff;text-transform:uppercase;font-weight:bold;padding:7px 25px;float:right}

#search_det, #search_text{margin:15px 0;}
.search_rez{margin:10px 0;}
.sch_page{font-size: 14px;line-height: 15px;text-decoration: none;display: inline-block;margin:0 2px;color:#323232;}
/*End Search Page*/

.index{position: relative;}
.index .navigation{background:none;position:absolute;top:0;z-index:1000;}

body .fade{opacity: 1;}
.div-slider{margin:0px 0;height:320px;position:relative;overflow:hidden;-webkit-box-sizing: content-box;-moz-box-sizing: content-box;box-sizing: content-box;width:100%;min-width:980px;}
@media (max-width:768px) and (min-width:640px) {.div-slider {padding-top: 45px}}
.y670{display:none!important;}
.y320{display:none!important;}

.navigation{background: url(../img/nav.jpg) no-repeat;height:89px;background-size:cover;width:100%;}

#responsmenu, #responsmenu ul, #responsmenu ul li, #responsmenu ul li a, #responsmenu #menu-button {margin: 0;padding: 0;border: 0;list-style: none;line-height: 1;display: block;position: relative;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
#responsmenu:after, #responsmenu > ul:after {content: ".";display: block;clear: both;visibility: hidden;line-height: 0;height: 0;}

#responsmenu #menu-button {display: none;}

#responsmenu #menu-line {position: absolute;top: 0;left: 0;height: 0;-webkit-transition: all 0.25s ease-out;-moz-transition: all 0.25s ease-out;-ms-transition: all 0.25s ease-out; -o-transition: all 0.25s ease-out;transition: all 0.25s ease-out;}

#responsmenu.align-center > ul {font-size: 0;text-align: center;}
#responsmenu.align-center > ul > li {display: inline-block;float: none;}
#responsmenu.align-center ul ul {text-align: left;}
#responsmenu.align-right > ul > li {float: right;}
#responsmenu.align-right ul ul {text-align: right;}

#responsmenu ul{width:980px;margin:0 auto;}
#responsmenu > ul > li{float: left;margin-right:0;width:193px;}
#responsmenu > ul > li > a {padding: 32px;font-size: 15px;text-decoration: none;color: #fff;-webkit-transition: color .1s ease;-moz-transition: color .1s ease;-ms-transition: color .1s ease;-o-transition: color .1s ease;transition: color .1s ease;}
#responsmenu > ul > li:hover > a{background:#fff;color:#1c1c1c;text-transform:uppercase;font-weight:bold;}
#responsmenu > ul > li:hover{background:#fff;}
#responsmenu > ul > li:hover > a:before{content:'';background:#fff;height:5px;width:193px;position:absolute;top:-5px;left:0;z-index:1010;box-shadow:1px -1px 1px rgba(0, 0, 0, 0.2);-moz-box-shadow:1px -1px 1px rgba(0, 0, 0, 0.2);-webkit-box-shadow:1px -1px 1px rgba(0, 0, 0, 0.2);}
#responsmenu > ul > li.active > a {}
#responsmenu > ul > li.active > a > span{box-shadow:0px 2px 0px 0px #ed1c24;-moz-box-shadow:0px 2px 0px 0px #ed1c24;-webkit-box-shadow:0px 2px 0px 0px #ed1c24;}
#responsmenu > ul > li.active:hover > a > span{box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
#responsmenu > ul > li.has-sub > a {padding-right: 24px;}
#responsmenu > ul > li.has-sub > a::after {position: absolute;top:0;right:0px;}
#responsmenu > ul > li.has-sub:hover > a::after {}

#responsmenu ul ul {position: absolute;z-index:1001;left: -9999px;padding:0 0 20px 0px;width:193px;background: #fff;box-shadow:0px 1px 1px rgba(0, 0, 0, 0.2);-moz-box-shadow:0px 1px 1px rgba(0, 0, 0, 0.2);-webkit-box-shadow:0px 1px 1px rgba(0, 0, 0, 0.2);}
#responsmenu li:hover > ul {left: auto;}
#responsmenu.align-right li:hover > ul {right: 0;}
#responsmenu ul ul ul {margin-left: 100%;top: 0;}
#responsmenu.align-right ul ul ul {margin-left: 0;margin-right: 100%;}
#responsmenu ul ul li {height: 0;-webkit-transition: height .1s ease;-moz-transition: height .1s ease;-ms-transition: height .1s ease;-o-transition: height .1s ease;transition: height .1s ease;}
#responsmenu ul li:hover > ul > li {height:auto;}
#responsmenu ul ul li a {padding:7px 20px 7px 30px;font-size: 15px;text-decoration: none;color:#323232;-webkit-transition: color .1s ease;-moz-transition: color .1s ease;-ms-transition: color .1s ease;-o-transition: color .1s ease;transition: color .1s ease;}
#responsmenu ul ul li:hover > a, #responsmenu ul ul li a:hover {color: #F55856;}
#responsmenu ul ul li.has-sub > a::after {position: absolute;top: 13px;right: 10px;width: 4px;height: 4px;border-bottom: 1px solid #dddddd;border-right: 1px solid #dddddd;content: "";-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-ms-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);-webkit-transition: border-color 0.1s ease;-moz-transition: border-color 0.1s ease;-ms-transition: border-color 0.1s ease;  -o-transition: border-color 0.1s ease;transition: border-color 0.1s ease;}
#responsmenu.align-right ul ul li.has-sub > a::after {right: auto;left: 10px;border-bottom: 0;border-right: 0;border-top: 1px solid #dddddd;border-left: 1px solid #dddddd;}
#responsmenu ul ul li.has-sub:hover > a::after {border-color: #fff;}
@media (max-width:768px) and (min-width:640px){
  .index #responsmenu {background:#fff;}
  .index #responsmenu > ul > li > a{padding:15px 14px;}
  .index #responsmenu ul li a, .index #responsmenu ul ul li a {color:#323232;box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
  .index #responsmenu > ul > li:hover > a{color:#1c1c1c;}
  .index #responsmenu > ul > li:hover{background:#fff;}
  .index #responsmenu > ul > li:hover > a > span, .index #responsmenu > ul > li.active:hover > a > span{color:#ed1c24;}
  .index #responsmenu .submenu-button {top:0px;}
  .index #responsmenu .submenu-button::after{left:36px;top:17px;background: url(../img/open.png) right center no-repeat;-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);}
  .index #responsmenu .submenu-button.submenu-opened:after{left:39px;top:17px;background: url(../img/open.png) right center no-repeat;-webkit-transform: rotate(-180deg);-moz-transform: rotate(-180deg);-ms-transform: rotate(-180deg);-o-transform: rotate(-180deg);transform: rotate(-180deg);}
  
  #responsmenu {width: 100%;height:auto;z-index:1000;}
  #responsmenu > ul {width: 100%;display: block!important;}
  #responsmenu.align-center > ul, #responsmenu.align-right ul ul {text-align: left;}
  #responsmenu > ul > li{width: auto!important;margin-right:0;float:left;height: auto;position:relative;}
  #responsmenu ul ul li, #responsmenu ul li:hover > ul > li {width:auto;height: auto;display:block;border-top:none;float: none;padding-right:0}
  #responsmenu > ul > li:hover > a{text-transform:none;font-weight:normal;background:none;}
  #responsmenu > ul > li:hover{background:none;}
  #responsmenu > ul > li:hover > a > span, #responsmenu > ul > li.active:hover > a > span{color: #fff;}
  #responsmenu > ul > li > ul > li:hover > a > span, #responsmenu > ul > li.active > ul > li:hover > a > span{box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;color:#ed1c24;}
  #responsmenu > ul > li:hover > a:before{content:'';background:none;height:auto;width:auto;position:absolute;top:-0;left:0;z-index:1010;box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
  #responsmenu > ul > li > a{padding:36px 8px 37px 8px;}
  #responsmenu ul li a, #responsmenu ul ul li a {width: 100%;}
  #responsmenu > ul > li, #responsmenu.align-center > ul > li, #responsmenu.align-right > ul > li {float:left;display: block;}
  #responsmenu ul ul li a {padding:15px 20px 15px 20px;font-size: 15px;color: #000000;background: none;}
  #responsmenu ul ul li:hover > a, #responsmenu ul ul li a:hover {color: #000000;}
  #responsmenu ul ul ul li a {padding-left: 40px;}
  #responsmenu ul ul, #responsmenu ul ul ul {position: absolute;z-index:1001;left: -9999px;padding:0 0 20px 10px;width:193px;background: #fff;box-shadow:0px 1px 1px rgba(0, 0, 0, 0.2);-moz-box-shadow:0px 1px 1px rgba(0, 0, 0, 0.2);-webkit-box-shadow:0px 1px 1px rgba(0, 0, 0, 0.2);}
  #responsmenu li:hover > ul{left:auto;}
  #responsmenu > ul > li.has-sub > a::after, #responsmenu ul ul li.has-sub > a::after {display: none;}
  #menu-line {display: none;}
  #responsmenu #menu-button {display: none;}
  #responsmenu .submenu-button {position: absolute;z-index: 10;right: 0;top:20px;display: block;height: 50px;width: 50px;cursor: pointer;}
  #responsmenu .submenu-button::after {content: '';position: absolute;top:15px;left: 26px;display: block;width:14px;height:14px;background: url(../img/arr_right.png) right center no-repeat;z-index: 99;-webkit-transform: rotate(90deg);-moz-transform: rotate(90deg);-ms-transform: rotate(90deg);-o-transform: rotate(90deg);transform: rotate(90deg);}
  #responsmenu .submenu-button::before {content: '';position: absolute;display: block;z-index: 99;}
  #responsmenu .submenu-button.submenu-opened:after {left: 29px;top:20px;-webkit-transform: rotate(-90deg);-moz-transform: rotate(-90deg);-ms-transform: rotate(-90deg);-o-transform: rotate(-90deg);transform: rotate(-90deg);}
	}
@media all and (max-width:638px) {
  #responsmenu {width: 100%;height:auto;z-index:1000;}
  #responsmenu > ul {width: 100%;display: block!important;background:#fff}
  #responsmenu.align-center > ul, #responsmenu.align-right ul ul {text-align: left;}
  #responsmenu > ul > li{width:95%!important;margin-right:0;float:none;height: auto;position:relative;}
  #responsmenu ul ul li, #responsmenu ul li:hover > ul > li {width:46%;height: auto;display:inline-block;border-top:none;float: none;padding-right:0}
  #responsmenu > ul > li:hover > a{text-transform:none;font-weight:normal;}
  #responsmenu > ul > li:hover > a > span, #responsmenu > ul > li.active:hover > a > span, #responsmenu > ul > li > ul > li:hover > a > span, #responsmenu > ul > li.active > ul > li:hover > a > span{box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;color:#ed1c24;}
  #responsmenu > ul > li:hover > a:before{content:'';background:none;height:auto;width:auto;position:absolute;top:-0;left:0;z-index:1010;box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
  #responsmenu > ul > li > a{padding:15px}
  #responsmenu ul li a, #responsmenu ul ul li a {width: 100%;color:#323232;}
  #responsmenu > ul > li, #responsmenu.align-center > ul > li, #responsmenu.align-right > ul > li {float:left;display: block;}
  #responsmenu ul ul li a {padding:15px 0px 15px 30px;font-size: 15px;color: #000000;background: none;}
  #responsmenu ul ul li:hover > a, #responsmenu ul ul li a:hover {color: #000000;}
  #responsmenu ul ul ul li a { padding-left: 40px;}
  #responsmenu ul ul, #responsmenu ul ul ul {position: relative;left:-7px;right: auto;width:107%;margin: 0;z-index:1000;overflow:hidden;}
  #responsmenu ul ul{padding:0;box-shadow:0px -2px 2px #e0e0e0 inset;-moz-box-shadow:0px -2px 2px #e0e0e0 inset;-webkit-box-shadow:0px -2px 2px #e0e0e0 inset;}
  #responsmenu li:hover > ul{left:-7px;}
  #responsmenu > ul > li.has-sub > a::after, #responsmenu ul ul li.has-sub > a::after {display: none;}
  #menu-line {display: none;}
  #responsmenu #menu-button {display: none;}
  #responsmenu .submenu-button {position: absolute;z-index: 10;right: 0;top:0px;display: block;height: 52px;width: 52px;cursor: pointer;}
  #responsmenu .submenu-button::after {content: '';position: absolute;top:16px;left: 26px;display: block;width:14px;height:14px;background: url(../img/open.png) right center no-repeat;z-index: 99;}
  #responsmenu .submenu-button::before {content: '';position: absolute;display: block;z-index: 99;}
  #responsmenu .submenu-button.submenu-opened:after {-webkit-transform: rotate(-180deg);-moz-transform: rotate(-180deg);-ms-transform: rotate(-180deg);-o-transform: rotate(-180deg);transform: rotate(-180deg);}
}

/*MenuProduct*/
.menu-top{background:#20324c;width:100%;margin-bottom:30px}

.page_catalog{display: none;}
#cssmenu, #cssmenu ul, #cssmenu ul li a, #cssmenu #menu-button {margin: 0;padding: 0;border: 0;list-style: none;line-height: 1;display: block;position: relative;-webkit-box-sizing:border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
#cssmenu:after, #cssmenu > ul:after {content: ".";display: block;clear: both;visibility: hidden;line-height: 0;height: 0;}
#cssmenu #menu-button {display: none;}
#cssmenu {margin:0 auto;width: 980px;line-height: 1;}
#menu-line {position: absolute;top: 0;left: 0;height: 0;background:none;-webkit-transition: all 0.25s ease-out;-moz-transition: all 0.25s ease-out;-ms-transition: all 0.25s ease-out;-o-transition: all 0.25s ease-out;transition: all 0.25s ease-out;}
#cssmenu.align-center > ul {font-size: 0;text-align: center;}
#cssmenu.align-center > ul > li {display: inline-block;float: none;}
#cssmenu.align-center ul ul {text-align: left;}
#cssmenu.align-right > ul > li {float: right;}
#cssmenu.align-right ul ul {text-align: right;}
#cssmenu.align-right li:hover > ul {right: 0;}
#cssmenu.align-right ul ul ul {margin-left: 0;margin-right: 100%;}
#cssmenu.align-right ul ul li.has-sub > a::after {right: auto;left: 10px;border-bottom: 0;border-right: 0;}
#cssmenu > ul > li {float: left;text-align: center;width:245px;margin: 0;padding: 0;border: 0;list-style: none;line-height: 1;display: block;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
#cssmenu > ul > li > a {padding:20px 0px;font-size: 16px;text-decoration: none;text-transform: uppercase;color: #fff;}
#cssmenu > ul > li:hover > a, #cssmenu > ul > li.active > a {color: #ed1c24;background:#fff;}
#cssmenu > ul > li.has-sub > a {padding-right: 25px;}
#cssmenu > ul > li.has-sub > a::after {position: absolute;top:20px;right:10px;content: "";width:14px;height:14px;background: url(../img/arrow_down.png) right center no-repeat;}
#cssmenu > ul > li.has-sub:hover > a::after {background: url(../img/arrow_down_hover.png) right center no-repeat;}
#cssmenu ul ul:before{position:absolute;min-height:60px;height:100%;content:'';background:#fff;border-bottom:1px solid #b2b2b2;box-shadow:0 3px 2px -1px rgba(0, 0, 0, 0.4);-moz-box-shadow:0 3px 2px -1px rgba(0, 0, 0, 0.4);-webkit-box-shadow:0 3px 2px -1px rgba(0, 0, 0, 0.4);z-index:999;left:-5000px;width:10000px;}
#cssmenu ul ul ul {margin-left: 100%;top: 0;}
#cssmenu ul ul li {display: inline-block;float:left;width:160px;text-align:left;position:relative;z-index:1000;padding-right:20px;-webkit-transition: height .1s ease;-moz-transition: height .1s ease;-ms-transition: height .1s ease;-o-transition: height .1s ease;transition: height .1s ease;}
#cssmenu ul li:hover > ul > li {height:auto;}
#cssmenu ul li:hover ul li a:hover span{box-shadow:0px 2px 0px 0px #ed1c24;-moz-box-shadow:0px 2px 0px 0px #ed1c24;-webkit-box-shadow:0px 2px 0px 0px #ed1c24;}
#cssmenu ul ul li a {display:table-cell;padding:15px 15px 0;vertical-align:middle;width:auto;font-size: 14px;line-height:1.3em;text-decoration: none;color: #323232;-webkit-transition: color .1s ease;-moz-transition: color .1s ease;-ms-transition: color .1s ease; -o-transition: color .1s ease; transition: color .1s ease;}
#cssmenu ul ul li.has-sub > a::after {position: absolute;top: 13px;right: 10px;width: 4px;height: 4px;content: "";-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-ms-transform: rotate(-45deg); -o-transform: rotate(-45deg);transform: rotate(-45deg);-webkit-transition: border-color 0.1s ease;-moz-transition: border-color 0.1s ease;-ms-transition: border-color 0.1s ease; -o-transition: border-color 0.1s ease;transition: border-color 0.1s ease;}
#cssmenu ul ul li.has-sub:hover > a::after {border-color: #ffffff;}
@media all and (min-width:980px){
	#cssmenu ul ul {position: absolute;left:0;z-index:1000;width:980px;height:85px;opacity:0;display:none!important}
	#cssmenu li:hover > ul {opacity:1;left:0;display:block!important}
	}
@media (max-width:768px) and (min-width:640px){
  #cssmenu {width: 100%;}
  #cssmenu > ul {width: 100%;display: block!important;}
  #cssmenu > ul > li{position:relative;display:inline-block;width:200px;}
  #cssmenu > ul > li > a{padding:20px;}
  #cssmenu > ul > li:hover > a, #cssmenu > ul > li.active > a {color: #fff;background:#20324c;}
  #cssmenu.align-center > ul, #cssmenu.align-right ul ul {text-align: left;}
  #cssmenu ul li {height: auto;}
  #cssmenu ul ul li, #cssmenu ul li:hover > ul > li {height: auto;display:block;border-top:none;padding-right:0;float: none;width:200px}
  #cssmenu ul li a, #cssmenu ul ul li a {width:auto;}
  #cssmenu > ul > li, #cssmenu.align-center > ul > li, #cssmenu.align-right > ul > li {float: left;text-align:left;}
  #cssmenu ul ul li a {padding:10px 10px 10px 30px;height: auto;display: block;color: #323232;}
  #cssmenu ul ul li:hover > a, #cssmenu ul ul li a:hover, #cssmenu ul ul li.active ul li a:hover, #cssmenu ul ul li ul li.active a:hover, #cssmenu ul ul li.active ul li.active a {color: #ed1c24!important;}
  #cssmenu ul li:hover ul li a:hover span{box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
  #cssmenu ul ul ul li{width:auto!important;height: auto;display:block!important;}
  #cssmenu ul ul ul li a, #cssmenu ul ul li.active ul li a {padding:7px 0px 7px 30px;font-style:italic;color: #696969!important;}
  #cssmenu ul ul{position: absolute;left:0;right: 0;z-index:1000;width:240px;height:auto;background:#fff;border-bottom:1px solid #b2b2b2;box-shadow:0 3px 2px -1px rgba(0, 0, 0, 0.4);-moz-box-shadow:0 3px 2px -1px rgba(0, 0, 0, 0.4);-webkit-box-shadow:0 3px 2px -1px rgba(0, 0, 0, 0.4);}
  #cssmenu ul ul ul {position: relative;left: 0;right: auto;width: 100%;margin: 0;background:#fff;border-bottom:none;box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
  #cssmenu ul ul:before{display:none;}
  #cssmenu > ul > li.has-sub > a::after, #cssmenu ul ul li.has-sub > a::after {display: none;}
  #menu-line {display: none;}
  #cssmenu #menu-button {display: none;}
  #cssmenu #menu-button::after { content: '';position: absolute;top: 20px;right: 20px;display: block;width: 15px;height: 2px;background: #000000;}
  #cssmenu #menu-button::before {content: '';position: absolute;top: 25px;right: 20px;display: block;width: 15px;height: 3px;border-top: 2px solid #000000;border-bottom: 2px solid #000000;}
  #cssmenu .submenu-button {position: absolute;z-index: 10;right:0;top: 0;display: block;height: 52px;width: 52px;cursor: pointer;}
  #cssmenu .submenu-button::after {content: '';position: absolute;top:20px;left:26px;display: block;width:14px;height:14px;background: url(../img/arrow_down.png) right center no-repeat;z-index: 99;}
  #cssmenu .submenu-button::before {content: '';position: absolute;left: 21px;top: 20px;display: block;z-index: 99;}
  #cssmenu .submenu-button.submenu-opened:after {background: url(../img/arrow_down_hover.png) right center no-repeat;-webkit-transform: rotate(-180deg);-moz-transform: rotate(-180deg);-ms-transform: rotate(-180deg);-o-transform: rotate(-180deg);transform: rotate(-180deg);}
  .page_catalog #cssmenu .submenu-button::after{width: 2px;height: 11px;background: #fff;top: 21px;left: 26px;}
  .page_catalog #cssmenu .submenu-button::before{left: 21px;top: 26px;width: 11px;height: 2px;background: #fff;}
  .page_catalog #cssmenu .submenu-button.submenu-opened:after{display: none;}
  .page_catalog #cssmenu .submenu-button.submenu-opened:before{background: #ed1c24;}
  .page_catalog #cssmenu ul ul li .submenu-button{width: 10px;height:7px;}
  .page_catalog #cssmenu ul ul li .submenu-button::before {left: 26px;}
  .page_catalog #cssmenu ul ul li.has-sub .submenu-button{top: -5px;}
  .page_catalog #cssmenu ul ul li.has-sub .submenu-button::after{background: url(../img/open.png) right center no-repeat;width: 10px;height:7px;}
  .page_catalog #cssmenu ul ul li.has-sub .submenu-opened::before{background: url(../img/close.png) right center no-repeat;width: 10px;height:7px;top:20px;-webkit-transform: rotate(-180deg);-moz-transform: rotate(-180deg);-ms-transform: rotate(-180deg);-o-transform: rotate(-180deg);transform: rotate(-180deg);}
  .page_catalog .menu-top{margin-top:200px;}
	}
@media all and (max-width: 639px) {
  .page_catalog{display: block;position:relative;margin-top:-80px;}
  #cssmenu {width: 100%;}
  #cssmenu > ul {width: 100%;display: block!important;}
  #cssmenu > ul > li{position:relative;background:#20324c;}
  #cssmenu > ul > li > a{padding:20px;}
  #cssmenu > ul > li:hover > a, #cssmenu > ul > li.active > a {color: #fff;background:#20324c;}
  #cssmenu.align-center > ul, #cssmenu.align-right ul ul {text-align: left;}
  #cssmenu ul li {width: 100%;height: auto;}
  #cssmenu ul ul li, #cssmenu ul li:hover > ul > li {width:auto;height: auto;display:block;border-top:none;float: none;padding-right:0}
  #cssmenu ul li a, #cssmenu ul ul li a {width: 100%;}
  #cssmenu > ul > li, #cssmenu.align-center > ul > li, #cssmenu.align-right > ul > li {float: none;text-align:left;}
  #cssmenu ul ul li a {padding:10px 0 10px 30px;height: auto;display: block;color: #323232;}
  #cssmenu ul ul li:hover > a, #cssmenu ul ul li a:hover, #cssmenu ul ul li.active ul li a:hover, #cssmenu ul ul li ul li.active a:hover, #cssmenu ul ul li.active ul li.active a {color: #ed1c24!important;}
  #cssmenu ul li:hover ul li a:hover span{box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
  #cssmenu ul ul ul li{width:auto!important;height: auto;display:block!important;}
  #cssmenu ul ul ul li a, #cssmenu ul ul li.active ul li a {padding:7px 0px 7px 30px;font-style:italic;color: #696969!important;}
  #cssmenu ul ul{width:100%;padding:5px 0;}
  #cssmenu ul ul:before{display:none;}
  #cssmenu ul ul, #cssmenu ul ul ul {position: relative;left: 0;right: auto;width: 100%;margin: 0;background:#fff;}
  #cssmenu > ul > li.has-sub > a::after, #cssmenu ul ul li.has-sub > a::after {display: none;}
  #menu-line {display: none;}
  #cssmenu #menu-button {display: none;}
  #cssmenu #menu-button::after { content: '';position: absolute;top: 20px;right: 20px;display: block;width: 15px;height: 2px;background: #000000;}
  #cssmenu #menu-button::before {content: '';position: absolute;top: 25px;right: 20px;display: block;width: 15px;height: 3px;border-top: 2px solid #000000;border-bottom: 2px solid #000000;}
  #cssmenu .submenu-button {position: absolute;z-index: 10;right: 0;top: 0;display: block;height: 52px;width: 52px;cursor: pointer;}
  #cssmenu .submenu-button::after {content: '';position: absolute;top:20px;left: 26px;display: block;width:14px;height:14px;background: url(../img/arrow_down.png) right center no-repeat;z-index: 99;}
  #cssmenu .submenu-button::before {content: '';position: absolute;left: 21px;top: 20px;display: block;z-index: 99;}
  #cssmenu .submenu-button.submenu-opened:after {background: url(../img/arrow_down_hover.png) right center no-repeat;-webkit-transform: rotate(-180deg);-moz-transform: rotate(-180deg);-ms-transform: rotate(-180deg);-o-transform: rotate(-180deg);transform: rotate(-180deg);}
  .page_catalog #cssmenu .submenu-button::after{width: 2px;height: 11px;background: #fff;top: 21px;left: 26px;}
  .page_catalog #cssmenu .submenu-button::before{left: 21px;top: 26px;width: 11px;height: 2px;background: #fff;}
  .page_catalog #cssmenu .submenu-button.submenu-opened:after{display: none;}
  .page_catalog #cssmenu .submenu-button.submenu-opened:before{background: #ed1c24;}
  .page_catalog #cssmenu ul ul li.has-sub .submenu-button{width:100%;left: -12px;top: -5px;}
  .page_catalog #cssmenu ul ul li.has-sub .submenu-button::after{background: url(../img/open.png) right center no-repeat;width: 10px;height:7px;}
  .page_catalog #cssmenu ul ul li.has-sub .submenu-opened::before{background: url(../img/close.png) right center no-repeat;width: 10px;height:7px;top:20px;-webkit-transform: rotate(-180deg);-moz-transform: rotate(-180deg);-ms-transform: rotate(-180deg);-o-transform: rotate(-180deg);transform: rotate(-180deg);}
  .page_catalog .menu-top{margin-top:200px;}
  }
 /*EndMenuProduct*/

/* Middle*/
.middle{width: 980px;margin: 0 auto;padding: 0 0 350px;position: relative;}
.middle:after {display: table;clear: both;content: '';}

.Bread{margin:30px 0 40px 0;}
.sp{color:#878788;}
.B_crumb, .B_homeCrumb{color:#878788;text-decoration:underline;}
.B_lastCrumb{text-decoration:none;color:#323232;}

.mainnews {width:74%;float:left;overflow:hidden;}
.mainnews article{border-top:1px solid #b1b1b1;position:relative;float:left;width:33%}
.mainnews article h3{margin:20px 10px 20px 0;}
.mainnews article h3 a{text-decoration:none;overflow:hidden;max-height:67px;min-height:67px;display:block;font-weight:bold;color:#323232;}
.mainnews article p a{text-decoration:none;color:#323232;font-size:15px;margin-right:10px;display:block;}
.mainnews article p a:hover{text-decoration:underline;}
.mainnews article .time{color:#323232;margin-bottom:0;font-size:16px;}

.all_news{display:none}

.mainpod{float:left;width:24%;overflow:hidden;}
.mainpod h2{border-bottom:1px solid #b1b1b1;padding-bottom:30px;margin-left:10px}
.mainpod ul{list-style:none;margin:20px 0 0 0 !important}
.mainpod ul li{padding:0 10px;display:block;text-transform:uppercase;}
.mainpod ul li:nth-child(odd){background:#e8e8e8;border:1px solid #dcdcdc;}
.mainpod ul li:hover{background:#ed1c24;}
.mainpod ul li:hover a{color:#fff;background: url(../img/arr_right.png) right center no-repeat;}
.mainpod ul li a{display:block;text-decoration:none;color:#323232;width:100%;padding:8px 0;}

.writer{margin-left:20px;float:left;width:24%;overflow:hidden;}
.writer h3{margin:108px 0 60px 0;}
.writer input[type="text"], textarea{border:none;border-bottom:1px solid #b1b1b1;padding:5px 0;margin:15px 0;color:#444;font-size:14px;display:block;width:100%;height:20px;}
.writer input[type="submit"]{border:none;background: url(../img/send.png) no-repeat;width:30px;height:30px;text-indent:-999em;}

.container {width: 100%;float: left;overflow: hidden;}
.container ul, .container ol{margin:5px 0 5px 25px;}
.container ul li{list-style-type:disc;}
.container ol li{list-style-type:decimal;margin-bottom:20px;}
.container h5 a{
    color:#ed1c24;
    text-decoration:none; 
    display: flex;
    align-items: center;
    gap: 11px;
}
.container h5 a span{border-bottom:1px dotted #ed1c24;display:inline;}
.container h5 a:hover span{border-bottom:none;cursor:pointer}
.container a{
    display: inline;
}
.content-table td{
    border: 1px solid #000000;
}
.content {padding: 0 0 10px 190px;}
.maincontent {padding: 0 0 10px 0;min-height:350px;}
.Bread * {
    display: inline;
}
.compare {margin-left: 50%;position: relative;right: 0;width: 480px;overflow:hidden;height:auto;}
.compare_title{margin-bottom:15px;overflow:hidden;}
.compare_title h3{float:left;margin:0}
.compare_title a.compare_link, .add_compare{color:#ed1c24;text-decoration:none;padding-left:25px;background: url(../img/compare.png) 0 center no-repeat; }
.compare_link{float:right;}
.compare_title a.compare_link span, .add_compare span{border-bottom:1px dotted #ed1c24;}
.compare_title a.compare_link:hover span, .add_compare:hover span{border-bottom:none;}
.compare_block{float:left;border:1px solid #b1b1b1;background:#fff;padding:4px 4px 4px 8px;font-style:italic;margin:0 10px 10px 0;}
.compare_block .name_prod{float:left}
.compare_block .close{background: url(../img/del.png) no-repeat;width:15px;height:15px;float:right;margin:10px 0 0 15px}
.compare_block .close:hover{cursor:pointer}

.add_compare:hover{cursor:pointer;}

.comparison a.comparison-link {
    text-decoration: none;
    border: none;
    &:hover {
        border: none;
    }
}

.quality{float:left;margin:45px 0;}
.download{margin-bottom:50px;display:block;color:#ed1c24;}
.download img{float:left}
.download span{float:left;text-decoration:underline;margin-left:5px}
.download:hover span{text-decoration:none;}

.prod_right{float:right;}
.prod_right h1{position:relative;display:inline-block;margin:30px 0 20px 0;width:100%;}

/*Tabs Product*/
.product_tabs{float:right;width:515px;}
.product_tabs > div, .product_tabs > input { display: none;position:relative;}
.product_tabs label {padding:0px;margin:0 20px 0 0;cursor: pointer; position: relative;background: #fff;font-size:14px;float:left;}
.product_tabs label span{border-bottom:1px dotted #20324c;}
.product_tabs label:hover span{border-bottom:none;color:#ed1c24;}
.product_tabs input[type="radio"]:checked + label, .product_tabs input[type="radio"]:checked + label a, .product_tabs input[type="radio"]:checked + label span{background: #fff;color:#ed1c24;text-decoration:none;border-bottom:none;}
.product_tabs > input:nth-of-type(1):checked ~ div:nth-of-type(1), .product_tabs > input:nth-of-type(2):checked ~ div:nth-of-type(2), .product_tabs > input:nth-of-type(3):checked ~ div:nth-of-type(3), .product_tabs > input:nth-of-type(4):checked ~ div:nth-of-type(4){ display: block; padding: 5px;clear:both;width:510px}
.product_tabs label a{text-decoration:none;color: #20324c;}
.product_tabs table{color:#696969;/*width:100%!important;*/margin:15px 0;border-collapse: collapse;border-spacing: 0;}
.product_tabs table, .product_tabs td{border:none;}
.product_tabs tr{border-bottom:1px solid #e5e5e5;}
.product_tabs table a{text-decoration:underline;color:#323232;}
.product_tabs table a:hover{text-decoration:none;}
.product_tabs .doc table tr td:last-child{text-align:right}
.product_tabs .doc table tr{border-bottom:none;}
.product_tabs .doc table tr td{padding:0;}
.product_tabs table tr td:first-child{padding-right:20px;width:40%}
.product_tabs table tr td{padding:6px;line-height:1.3em;word-wrap:break-word}
/*End Tabs Product*/

.novelty, .hit{position:absolute;text-indent:-999em;z-index:990;right:0;top:0;width:41px;height:41px;}
.novelty{background: url(../img/hit.png) center center no-repeat;}
.hit{background: url(../img/novelty.png) center center no-repeat;}

.prod_left{float:left;width:260px;}
.prod_left center{float:left;}
.prod_img{position:relative;width:260px;height:220px;margin-bottom:20px}
.prod_img:hover .zoom{background: url(../img/loupe.png) center center no-repeat;width:30px;height:21px;position:absolute;z-index:1000;right:50%;bottom:0;}

/* These hieghts and widths should be edited to your images 
ul#pikame{padding-left:0;width:250px;margin:0;}
.pika_main{width:230px;margin:0;position: relative;}
#pikame li{margin:5px 5px 0 0;float: left;position:relative;overflow:hidden;}
/* thats all you NEED to edit. But continue if you'd like. 
ul#pikame li img{position:relative;cursor:pointer;}
.pika_main img{}
.pikachoose li{float:left;position:relative;overflow:hidden;list-style:none;}
.pika_play{opacity:0;display:none!important;}
.pika_play img{border:none !important;}
.pika_caption{width:100%;display:none;}
.pika_navigation{display:none}
.pika_navigation a{}
.pika_navigation a:hover{}
.pika_navigation{}*/	

/* line 6, ../sass/lightbox.sass */
#lightboxOverlay {position: absolute;top: 0;left: 0;z-index: 9999;background-color: black;filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=85);opacity: 0.85;display: none;}
/* line 15, ../sass/lightbox.sass */
#lightbox {position: absolute;left: 0;width: 100%;z-index: 10000;text-align: center;line-height: 0;font-family: "lucida grande", tahoma, verdana, arial, sans-serif;font-weight: normal;}
/* line 24, ../sass/lightbox.sass */
#lightbox img {width: auto;height: auto; object-fit: contain}
/* line 27, ../sass/lightbox.sass */
#lightbox a img {border: none;}
/* line 30, ../sass/lightbox.sass */
.lb-outerContainer {position: relative;background-color: white;*zoom: 1;width: 250px;height: 250px;margin: 0 auto;-webkit-border-radius: 4px;-moz-border-radius: 4px;-ms-border-radius: 4px;-o-border-radius: 4px;border-radius: 4px;}
/* line 38, ../../../../.rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.1/frameworks/compass/stylesheets/compass/utilities/general/_clearfix.scss */
.lb-outerContainer:after {content: "";display: table; clear: both;}
/* line 39, ../sass/lightbox.sass */
.lb-container {padding: 10px;}
/* line 42, ../sass/lightbox.sass */
.lb-loader {position: absolute;top: 40%;left: 0%;height: 25%;width: 100%;text-align: center;line-height: 0;}
/* line 51, ../sass/lightbox.sass */
.lb-nav {position: absolute;top: 0;left: 0;height: 100%;width: 100%;z-index: 10;}
/* line 59, ../sass/lightbox.sass */
.lb-container > .nav {left: 0;}
/* line 62, ../sass/lightbox.sass */
.lb-nav a {outline: none;}
/* line 65, ../sass/sass */
.lb-prev, .lb-next {width: 49%;height: 100%;background-image: url("data:image/lightbox/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");/* Trick IE into showing hover */display: block;}
/* line 72, ../sass/lightbox.sass */
.lb-prev {left: 0;float: left; /*background: url(../img/lightbox/prev.png) left 48% no-repeat;*/ }
/* line 76, ../sass/lightbox.sass */
.lb-next {right: 0;float: right; /* background: url(../img/lightbox/next.png) right 48% no-repeat;*/ }
/* line 81, ../sass/lightbox.sass */
.lb-prev:hover {background: url(../img/prev.png) left 48% no-repeat;}
/* line 85, ../sass/lightbox.sass */
.lb-next:hover {background: url(../img/next.png) right 48% no-repeat;}
/* line 88, ../sass/lightbox.sass */
.lb-dataContainer {margin: 0 auto;padding-top: 5px;*zoom: 1;width: 100%;-moz-border-radius-bottomleft: 4px;-webkit-border-bottom-left-radius: 4px;-ms-border-bottom-left-radius: 4px;-o-border-bottom-left-radius: 4px;border-bottom-left-radius: 4px;-moz-border-radius-bottomright: 4px;-webkit-border-bottom-right-radius: 4px;-ms-border-bottom-right-radius: 4px;-o-border-bottom-right-radius: 4px;border-bottom-right-radius: 4px;}
/* line 38, ../../../../.rvm/gems/ruby-1.9.2-p290/gems/compass-0.12.1/frameworks/compass/stylesheets/compass/utilities/general/_clearfix.scss */
.lb-dataContainer:after {content: "";display: table;clear: both;}
/* line 95, ../sass/lightbox.sass */
.lb-data {padding: 0 10px;color: #bbbbbb;}
/* line 98, ../sass/lightbox.sass */
.lb-data .lb-details {width: 85%;float: left;text-align: left;line-height: 1.1em;}
/* line 103, ../sass/lightbox.sass */
.lb-data .lb-caption {font-size: 13px;font-weight: bold;line-height: 1em;}
/* line 107, ../sass/lightbox.sass */
.lb-data .lb-number {display: block;clear: left;padding-bottom: 1em;font-size: 11px;}
/* line 112, ../sass/lightbox.sass */
.lb-data .lb-close {width: 35px;float: right;padding-bottom: 0.7em;outline: none;}
/* line 117, ../sass/lightbox.sass */
.lb-data .lb-close:hover {cursor: pointer;}
.client_lightbox{overflow:hidden;float:left;}

/*Carousel*/
.carusel {margin: 2px auto;position: relative;overflow: hidden;z-index:100}
.thumbnails {margin: 0 auto;overflow: hidden;position: relative;width:245px;height:75px}
.thumbnails a{display:block;float:left}
.thumbnails a img{margin:0 3px}
.b-l2, .b-r2 {cursor: pointer;height:75px;width: 12px;position:absolute;z-index:99}
.b-l2 {background:#fff url(../img/l2.png) 0 50% no-repeat;left:0;}
.b-r2 {background:#fff url(../img/r2.png) 0 50% no-repeat;right:0;}
/*End Carousel*/

.related_prod{}
.related_prod h2{margin:20px 0 30px 0;}
.more_prod{float:right;padding-right:35px;background: url(../img/more_prod.png) right center no-repeat;margin:20px 0 0 0}
.more_prod span{border-bottom:1px dotted #272933;}
a.more_prod{text-decoration:none;color:#272933}
.more_prod:hover span{border-bottom:none;}

.related{height:270px;width:249px;margin:10px 21px 10px 0;float:left;}
.related:nth-child(3n+3){margin:10px 0px;}
.related img{border:2px solid #20324c;}
.related a{text-decoration:none;}
.related a span{color:#20324c;display:block;}
.related:hover a, .related a:hover{text-decoration:none;}

.prod_block{display:block;overflow:hidden;}
.prod_block:hover{-moz-box-shadow:1px 1px 1px 1px #e3e3e3, 0 2px 0 2px #e3e3e3 inset;-webkit-box-shadow: 1px 1px 1px 1px #e3e3e3, 0 2px 0 2px #e3e3e3 inset;box-shadow:1px 1px 1px 1px #e3e3e3, 0 2px 0 2px #e3e3e3 inset;}
.prod_block img{float:left;padding:20px 0 15px 10px}
.prod_block .descr{width:520px;float:right;padding-right:10px;}
.prod_block a{display:block;text-decoration:none;}
.prod_block a .name{font-size:18px;font-weight:bold;color:#323232;padding:25px 0;display:block;border-bottom:1px solid #dbdbdb;text-transform:uppercase;min-height:24px;overflow:hidden;position:relative;}
.prod_block a .intro{color:#696969;font-size:14px;padding:15px 0 5px 0;display:block;}
.prod_block .add_compare{padding-right:25px;background: url(../img/btn_compare.png) right center no-repeat;}
.prod_block .novelty, .prod_block .hit{height:61px}
.box {margin-bottom:30px}
.box .descr{width:585px;}

.public{margin-top:-40px;flex-wrap: wrap;
    display: flex;}
.news{overflow:hidden;display:inline-block;padding:30px 0;width:48%;margin-right:20px;}
.news:nth-child(2n-2){margin-right:0px;}
.news a{text-decoration:none;display:block;}
.news h2{font-size:18px;margin:0 0 30px 0;}
.news h2 a{color:#20324c}
.news p{overflow:hidden;max-height:144px;min-height:144px;}
.news p a{color:#323232;}
.news h2:hover a{text-decoration:none;color:#ed1c24;}
.news p:hover{text-decoration:underline;color:#323232;}

.details{background: url(../img/details.png) bottom center no-repeat;width:15px;height:15px;display:inline-block;margin:0px 0 0 5px;position:relative;top:2px;}

.pagination{margin:20px 0 0px 0;font-size:14px;text-align: center;text-transform:uppercase;}
.ditto_next_link, .ditto_previous_link{font-size: 14px;text-decoration: none;display: inline-block;margin:0 2px;font-weight:bold}
.ditto_next_link{color: #323232;}
.ditto_page, .ditto_previous_link{color: #696969;}
.ditto_currentpage, .ditto_page:hover{color: #ed1c24;}
.ditto_currentpage, .ditto_page{font-size: 14px;line-height: 15px;padding:4px 8px 5px;text-decoration: none;display: inline-block;margin:0 2px;}

table.service_center{border:none;float: left;width: 50%;}
table.service_center span{display:block}

.contacts{float:left;width:49%;margin:0px;}
.contacts table{border:none;float: left;width:98%;margin:0 0 30px 0;}
.contacts table span{display:block}
.contacts h4{margin:0 0 10px 0}

/*ListTown*/
#list_town{margin:60px 0 0 0!important;width:44%;padding: 0;}
#list_town .list_city {font-size:16px;text-transform:uppercase;background:#20324c;color: #fff;padding:12px 20px;width:100%;display:block;position:relative}
#list_town .list_city:hover{cursor:pointer;}
#list_town ul{max-height:250px;overflow-y:scroll !important;background: #fff;}
#list_town li {font-size:14px;background:#fff;padding:5px 0;list-style-type: none!important;}
#list_town li a{color: #20324c;padding:5px 20px;text-decoration: none;}
#list_town .list_city:after{content:'';background: url(../img/arrow.png) no-repeat;width:14px;height:14px;display: block;left: 95%;position: absolute;z-index:1001;top:23px}
#list_town .menu-open:after {top:12px;left: 93%;-webkit-transform: rotate(180deg); /* Chrome y Safari */-moz-transform: rotate(180deg); /* Firefox */filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */-o-transform: rotate(180deg); /* Opera */}
/*End ListTown*/

/*FAQ accordeon*/
#faq{float:left;width:49%;margin:0px;}
.horizontal-click > li{margin-bottom:0!important;font-size:14px;font-weight:bold;text-transform:uppercase;}
/*.horizontal-click > li > [name="vkl"] { display: none; }
.horizontal-click > li > :not(input):not(label) {overflow: hidden;height: 0;margin: 0;padding:0px 30px 0px 0;-webkit-transition: top 1s ease-out 0.5s;-moz-transition: top 1s ease-out 0.5s;-o-transition: top 1s ease-out 0.5s;transition: top 1s ease-out 0.5s;}
.horizontal-click > li > label {overflow: hidden;display: block;font-size:14px;font-weight:bold;text-transform:uppercase;color:#20324c;cursor: pointer;margin-bottom:30px;}
.horizontal-click > li > label:hover, .horizontal-click label:hover{color:#ed1c24;cursor:pointer;}
.horizontal-click > li > :checked + label + * {overflow: auto;height:auto}
.horizontal-click > li > :checked + label {cursor: default;}*/

.horizontal-click > li h3{display: block;font-size:14px;font-weight:bold;text-transform:uppercase;color:#20324c;cursor: pointer;margin-bottom:30px;}
.horizontal-click > li > div{font-weight:normal;text-transform:none;}
.horizontal-click .accord{display:none;padding:0px 30px 0px 0;}
.horizontal-click .hide{color:#ed1c24;background: url(../img/up-ar.png) right no-repeat;text-transform:none;font-size:14px;font-weight:normal;float:right;padding-right:15px;margin-bottom:15px;}
.horizontal-click .hide span{border-bottom:1px dotted #ed1c24;}
.horizontal-click .hide:hover{cursor:pointer;}
/*End FAQ accordeon*/

/*WriteForms*/
.question_ask{float:right;width:49%}
.question_ask .h2{margin:0 0 50px 0;}
.question_ask form{}
.question_ask input[type="text"], .question_ask textarea, .question_ask .select, #search_text input[type="text"]{border:1px solid #b9b9ba;display:block;padding:6px 10px;margin:22px 0;width:95%;text-transform:none;color:#6f6f72;}
.question_ask input[type="email"], .question_ask textarea, .question_ask .select, #search_text input[type="email"]{border:1px solid #b9b9ba;display:block;padding:6px 10px;margin:22px 0;width:95%;text-transform:none;color:#6f6f72;}

.question_ask textarea{height:100%;max-width:455px;min-width:455px;}
.question_ask .verif{height:35px;}
.question_ask img, .question_ask .verif label{float:left;display:block;}
.question_ask img{height:35px;border:1px solid #b9b9ba;}
.question_ask .verif .vericode{float:right;width:205px;margin:3px 3px 0 0;}
.question_ask .verif label{line-height:2.5em;margin-right:25px;}
.question_ask .sel{background:#fff url(../img/bg_select.png) center right no-repeat;overflow: hidden;position:relative;border:1px solid #b9b9ba;display:block;margin:22px 0;width:99%;color:#6f6f72;}
.question_ask select{width:395px;background:none;position: relative;z-index: 100;border:none;padding:5px 0px 5px 10px;width:100%;color:#6f6f72;font-family: "PT Sans", sans-serif !important;font-size: 16px;
    line-height: 24px;}
.question_ask select:hover{cursor:pointer}
.question_ask option{padding:5px 15px 5px}
.question_ask option:hover{background-color:#3d4a5d;cursor:pointer}
.question_ask center{float:left}

.question_ask .select{position:relative;z-index:999;padding:3px 0px;}
.question_ask .select div{background:#fff;position:absolute;z-index:10000;width:100%;border-width:0 1px 1px 1px;border-style:solid;border-color:#b9b9ba;left:-1px;}
.question_ask .slct {background:#fff;color:#8c9cb3;display:block;border:none;width:250px;padding:0 10px;text-decoration:none;/*Супер финт обрезаем текст чтобы не вылезал за рамку*/overflow: hidden;white-space:nowrap;text-overflow: ellipsis;-o-text-overflow: ellipsis;}
.question_ask .slct:after{background: url(../img/bg_select.png) no-repeat;content:'';width:14px;height:14px;position: absolute;right:10px;top:8px}
.question_ask .slct:hover{text-decoration:none;cursor:pointer;}
.question_ask .slct.active:after {top:8px;right:10px;-webkit-transform: rotate(180deg); /*Chrome y Safari*/-moz-transform: rotate(180deg); /*Firefox*/filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */-o-transform: rotate(180deg); /* Opera */}
.question_ask ul{margin:0 0 5px 10px;}
.question_ask li{list-style: none;cursor: pointer;display: block;padding:8px 0px;}
.question_ask li:hover {color: #ed1c24;}
/*End WriteForms*/

/*ComparePage*/
.compare_page table{border-collapse: collapse;border-spacing: 0;width:100%;height:100%;margin:0px;padding:0px;border:none;}
.compare_page table tr:first-child td:first-child{vertical-align:bottom;}
.compare_page table tr td:first-child {color:#878788;width:25%;}
.compare_page tr:nth-child(even), .compare_page tr:nth-child(2), .compare_page tr:first-child{background-color:#fff!important;}
.compare_page tr:nth-child(odd){background-color:#e8e8e8;}

.compare_page tr:nth-child(2) a{color:#323232;text-decoration:none;}
.compare_page td{vertical-align:middle;text-align:left;padding:10px;font-size:14px;}
.compare_page tr:nth-child(2) td:first-child{font-weight:normal;text-transform:none;}
.compare_page tr th{background-color:#006eb9;text-align:center;border-width:0px 0px 1px 1px;font-weight:bold;color:#fff;padding:7px;}

.print{background: url(../img/print.png) left center no-repeat;}
.print a{color:#ed1c24;margin-left:35px;}

.del_compare{background:#ff0a14 url(../img/delete.png) 92% no-repeat;color:#fff;text-decoration:none;padding:0 25px 2px 5px;font-size:13px;}
.del_compare:hover{cursor:pointer;}
/*EndComparePage*/

/*Page WereBuy*/
.partners{float:left;width:297px;margin:0px 20px 20px 0;line-height:1.5em;height:220px;}
.partners .images{width:80px;height:75px;/*-webkit-filter: grayscale(100%);-moz-filter: grayscale(100%);-ms-filter: grayscale(100%);-o-filter: grayscale(100%);filter: grayscale(100%);filter: gray;filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android *//*filter: gray;*/}
/*.partners:hover .images{-webkit-filter:none!important;-moz-filter:none!important;-ms-filter:none!important;-o-filter:none!important;filter:none!important;}*/
.partners h3{font-size:14px;text-transform:none;margin:10px 0 10px 0;overflow:hidden;max-height:20px;}
.partners span.city{max-height:auto;min-height:auto;}
.partners span{display:block;overflow:hidden;max-height:42px;}

/*Filters*/
.filters{margin-bottom:30px;position:relative;float:left;}
.first_sel{z-index:1000!important;}
.first_sel div{max-height:250px;overflow-y:scroll;z-index:10000!important;}
.filters .select{position:relative;float:left;margin-right:25px;z-index:999!important;width:290px;background:#20324c;}
.filters .select div{background:#20324c;position:absolute;z-index:1000;width:290px;}
.filters option{padding:5px 15px 5px}
.filters option:hover{background-color:#3d4a5d;cursor:pointer}
.filters input {font-size: 14px;border:none;}
.filters input[type="submit"]{background:#ec1c24;color:#fff;padding:0px 15px;text-align:center;margin:0;height:44px;}
.filters input[type="submit"]:hover{background:#20324c}
.filters .slct {background:#20324c;display:block;border:none;width:250px;padding:10px 10px 10px 30px;color: #fff;text-decoration:none;/*Супер финт обрезаем текст чтобы не вылезал за рамку	*/ overflow: hidden;white-space:nowrap;text-overflow: ellipsis;-o-text-overflow: ellipsis;}
.filters .slct:after{background: url(../img/arrow.png) no-repeat;content:'';width:14px;height:14px;position: absolute;right:10px;top:20px}
.filters .slct:hover{text-decoration:none;cursor:pointer;}
.filters .slct.active:after {top:10px;right:20px;-webkit-transform: rotate(180deg); /* Chrome y Safari */-moz-transform: rotate(180deg); /* Firefox */filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */-o-transform: rotate(180deg); /* Opera */}
.filters ul{margin:0 0 5px 9%;}
.filters li{list-style: none;cursor: pointer;display: block;color:#fff;padding:8px 0px;line-height:1.1em;}
.filters li:hover {color: #ed1c24;}

.fleft_block, .federal_network{float:left;width:635px;}
.fleft_block .first_sel div{overflow-x:hidden;}
/*EndFilters*/
.online-shop{float:right;width:345px;margin-top:-7px}
.online-shop p{margin:0;}
.online-shop a{display:block;padding:10px 20px;text-decoration:none;color: #20324c;text-transform:uppercase;}
.online-shop a:hover{color:#fff;background:#ec1c24 url(../img/a_hover.png) 95% center no-repeat;text-decoration:none;}
.online-shop hr{border-width:0 0 1px 0;border-color:#878788;border-style:solid;margin:15px 0;}
/*End Page WereBuy*/

#items{margin:30px 0 0 0;}
.center_downloads{display:inline-block;width:470px;overflow:hidden;margin:10px 40px 15px 0;}
.center_downloads:nth-child(2n-2){margin:10px 0 15px 0;}
.center_downloads h2{margin:0 0 20px 0;}
.center_downloads h2 a{text-decoration:none;color:#323232;}
.center_downloads .load{border-top:1px solid #878788;padding:20px 0 0 0;color:#696969}
.center_downloads .load a{color:#323232;}
.center_downloads .load table tr{padding:0;}
.center_downloads .load table tr td:last-child{float:right;}
#downloadCenter.filters .select div{position:relative;max-height:150px;overflow-y:scroll;}
#downloadCenter.filters li{width:235px}

/* Left Sidebar*/
.left-sidebar {float: left;width:180px;margin-left: -100%;position: relative;}

/*skin Clear*/
.skinClear{margin-top:10px;}
.skinClear, .skinClear ul {list-style:none;}
.skinClear ul {display:none;margin:0 0 0 10px;}
.skinClear li {list-style:none;font-size:16px;font-weight:bold;text-transform:uppercase;margin-bottom:30px;}
.skinClear a {color:#323232;display:block;position:relative;text-decoration:none;padding:0px;}
.skinClear a:hover, .skinClear li ul li ul li.active>a, .skinClear li ul li.active>a, .skinClear li ul li.active ul li.active>a{text-decoration:none;color:#ed1c24}
.skinClear li ul{margin:30px 0 30px 0px}
.skinClear li ul li {text-transform:none;font-size:14px;font-weight:normal}
.skinClear li ul li ul{margin-left:20px}
.skinClear li ul li ul li{font-style:italic;}
.skinClear li ul li ul li a, .skinClear li ul li.active ul li a{color:#696969}
.skinClear li ul li.active ul li a:before {content:'';display:none;}
.skinClear li ul li.active a.harFull:before, .skinClear li.active ul li.active a:before {background: url(../img/close.png) left center no-repeat;content:'';padding-right:15px;width:10px;height:7px;}
.skinClear li ul li a.harFull:before, .skinClear li.active ul li a:before {background: url(../img/open.png) left center no-repeat;content:'';padding-right:15px;width:10px;height:7px;}
.skinClear li ul li ul ul {margin: 10px 0 10px 20px}
.skinClear li ul li ul ul li {margin-bottom: 30px; margin-top: 30px; line-height: 18px;}
.skinClear a.cur {}
.skinClear a.harOpen {color:#ed1c24!important}
.skinClear li.active>ul>li.active>ul, .skinClear li.active>ul{display:block}
.skinClear li.active>ul>li>ul{display:none;}
.skinClear .color{color:#696969!important}
.skinClear li ul li a.color:before {background: url(../img/open.png) left center no-repeat!important;content:'';padding-right:15px;width:10px;height:7px;}
/*End skin Clear*/

/* Footer*/
footer{width:100%;background: #20324c;margin: -210px 0 0;height: 210px;position: relative;color:#fff;}
.footer {width: 980px;margin:0 auto;padding-top:15px;}
.block_f{float:left;width:22%;}
.block_f:last-child{width:33%;}
.block_f nav{margin-left:15%;}
.block_f nav a{display:table;color:#fff;text-decoration:none;font-size:15px;margin-top:28px;padding-bottom:2px}
.block_f nav a:hover span{box-shadow:0px 2px 0px 0px #ed1c24;-moz-box-shadow:0px 2px 0px 0px #ed1c24;-webkit-box-shadow:0px 2px 0px 0px #ed1c24;}
.block_f center{display:block;}
.logo_f{display:block;margin-bottom:0;}
.copy{font-style:italic;color:#7d8da3;font-size:13px;}
footer .search-form{margin-left:0px;margin-top:30px;position:relative;overflow:hidden;width:230px;border-bottom:1px solid #344762;padding-bottom:15px}
footer .search-form input[type="text"]{position:relative;left:0px;background: #20324c;font-size:14px;text-transform:none;color:#8c9cb3;float:left;}
footer .search-form:hover{border-bottom:1px solid #344762;padding-bottom:15px}
footer .phone{color:#fff;margin-left:0;margin-top:50px;}

/*Pop-up window*/
#msg{background: #F2F2F2;position: fixed;/*width:300px;*/left:40%;top:40%;min-width: 300px;z-index: 10000;-webkit-box-shadow:1px 1px 2px -1px #4a4a4a;-moz-box-shadow:1px 1px 2px -1px #4a4a4a;box-shadow:1px 1px 2px -1px #4a4a4a;}
#msg div{height:130px;display:table-cell;vertical-align:middle;text-align:center;padding:0 60px;font-size:16px;}
/*End Pop-up window*/

@media (max-width:1200px){
    .question_ask input[type="submit"]{margin-top:20px;}
}
@media (max-width:768px) and (min-width:640px){
	header{width:100%;}
	header form{display:none;}
	.phone{margin-left: 40px;}
	#adress{width:200px;}
	.where_buy{width:200px;}
	
	.index .navigation{height:auto}
	
	.div-slider{min-width:100%;}
	.anythingSlider-default{left:0!important; margin-left:0!important;width:100%!important}
	.anythingSlider-default .anythingControls ul{left:94%!important;margin-left:0px!important;}
	body .y980{display:none!important;}
	body .y320{display:none!important;}
	body .y670{display:block!important;}
	
	.middle{width:100%;padding:0 0 250px 0;}
	
	.mainnews{position:relative;width:100%;float:none;margin:0 15px;}
	.mainpod, .writer{width:95%;}
	.mainpod{padding-left:15px;}
	.writer{padding-right:15px;}
	.writer h3{margin:25px 0 80px 0}
	.writer center .fright{float:none;}
	
	.content, .maincontent{padding:0 15px 10px 15px;}
	
	.page_catalog{display:block}
	.page_catalog .menu-top{margin-top:0px;}
	
	.Bread{margin:30px 15px;}
	
	.compare{margin-left:15px;margin-right:15px;}
	
	.prod_block .descr{width:58%}
	.box .descr{width:72%} 
	
	.prod_right{width:57%}
	.product_tabs, .product_tabs > input:nth-of-type(1):checked ~ div:nth-of-type(1), .product_tabs > input:nth-of-type(2):checked ~ div:nth-of-type(2), .product_tabs > input:nth-of-type(3):checked ~ div:nth-of-type(3), .product_tabs > input:nth-of-type(4):checked ~ div:nth-of-type(4){width:100%;}
	.product_tabs label{width:42%;}
	.product_tabs > input:nth-of-type(4):checked ~ div:nth-of-type(4) td:first-child{width:82%}
	.product_tabs > input:nth-of-type(4):checked ~ div:nth-of-type(4) td:last-child{text-align:left}
	
	.related, .related:nth-child(3n+3){width:46%;margin:12px;}
	
	#faq, .question_ask{float:none;width:100%;}
	.question_ask input[type="text"]{float:left;width:44%;margin:0px 10px 20px 0}
	.question_ask input[type="email"]{float:left;width:44%;margin:0px 10px 20px 0}
	.question_ask textarea{max-width:90%;min-width:90%;clear:both;}
	.question_ask .verif .vericode{float:right;width:40%;margin-top:0px;margin-right:25px;}
	.question_ask .select{float:left;width:47%!important;margin:0px 10px 20px 0}
	
	.center_downloads{width:100%;height:auto;display:block;}
	.center_downloads table{width:98%;}
	.center_downloads tr td:first-child{width:88%!important;display:inline-block;}
	.center_downloads tr td:last-child{width:12%!important;display:inline-block;}
	#downloadCenter.filters{position:absolute;}
	#downloadCenter.filters .select{margin-right:15px;width:165px;}
	#downloadCenter.filters .slct{width:135px;padding:10px 10px 10px 20px;}
	#downloadCenter.filters .select div{position:relative;max-height:150px;overflow-y:scroll;width:165px;}
	#downloadCenter.filters li{width:135px}
	#items{margin-top:100px}
	
	#list_town ul{width:96%;}

	.contacts{width:100%;float:none;}
	.contacts table {border: none;float: left;width: 46%;margin: 0 20px 30px 0;height: 160px;}
	
	.fleft_block, .online-shop{width:48%;}
	.filters{width:100%;float:none;}
	.filters .select{margin-right:20px;margin-bottom:20px;z-index:auto!important;}
	.fleft_block .filters .select div{width:100%;}
	.partners{height:auto;margin:0 0 20px 0;width:100%;float:none;}
	
	.lb-prev{background: url(../img/lightbox/prev.png) left 48% no-repeat;}
	.lb-next{background: url(../img/lightbox/next.png) right 48% no-repeat;}
	
	.left-sidebar{display:none;}
	
	footer{height:auto;margin:0;overflow:hidden;}
	.footer {width:100%;margin:0 15px;height:370px;overflow:hidden;position:relative;}
	.block_f:first-child{bottom:3%;left:5%;}
	.block_f:nth-child(3n){right:5%}
	.block_f:last-child{bottom:10%;right:12%;}
	.block_f{display:inline-block;width:47%;position:absolute;}
	.logo_f{margin-bottom:10px;}
	
	footer .search-form{width:90%;}
	}
@media (min-width:360px) and (max-width:768px){
   .y320{display:none!important;}
   .y670{display:block!important;}
   }
@media (max-width:480px) and (min-width: 320px){
	.h2 {font-size: 18px !important;}
	
   .top_block{position:absolute;top:140px;width:100%;}
   .where_buy{width:100%;top:0;}
   #adress{display:none;}
		
	header form{display:none;}
	.logo{float:none;width:214px;margin:0 auto;display:block;}
	.phone{display: inline-block;float: none;margin-left: 0;margin-top: 0;}
	
	#search_text{width:100%}
	#search_text input[type="text"]{width:60%!important;display:inline-block;}
	#search_text input[type="submit"]{width:25%;display:inline-block;}
	.div-slider{top:180px;min-width:100%;}
	.anythingSlider-default{left:0!important; margin-left:0!important;width:100%!important}
	.anythingSlider-default .anythingControls ul{left:94%!important;margin-left:0px!important;}
	
	.navigation{background:#fff;height:100%;}
	
	body .y320{display:block!important;}
	body .y980{display:none!important;}
	
	.menu-top{margin-top:180px;margin-bottom:0;}
	.page_catalog .menu-top{margin-top:100px;}
	
	.middle{width:100%;padding:0 0 250px 0;}
	
	.mainnews, .mainpod, .writer{float:none;display:block;width:90%;padding:0 15px}
	
	.writer{margin-left:0}
	.writer h3{margin:40px 0 20px 0}
	.writer center .fright, .question_ask center{float:none;}

	.mainnews{position:relative}
	.mainnews h2{margin-bottom:10px;margin-left:0;margin-right:0;margin-top:30px;font-size:18px!important;}
	.mainnews article{border-top:none;float:none;width:98%;display:block;}
	.mainnews article p a{min-height:auto;max-height:100%;}
	
	.all_news{display:block;float:right;position:absolute;bottom:0px;right:10px;}
	
	.mainpod h2{margin-left:0;font-size:18px!important;}
	
	.container{overflow:visible}
	
	.content, .maincontent{padding:0 15px 10px 15px;}
		
	.Bread{margin:30px 15px;}
	
	.prod_block:hover{box-shadow:none;-moz-box-shadow:none;-webkit-box-shadow:none;}
	.prod_block img{float:none;display:block;}
	.prod_block .descr{float:none;width:100%;display:block;}
	.prod_block .descr .add_compare{float:right!important;}
	.prod_left{float:none;width:100%;display:block;}
	.prod_img{margin:0 auto 20px auto;}
	
	#faq, .question_ask{float:none;width:100%;}
	.question_ask .h2{margin: 0 0 30px;}
	.question_ask input[type="text"], .question_ask .select{width:93%;}
	.question_ask textarea{max-width:93%;min-width:93%;}
	.question_ask .verif .vericode{float:left;width:100px;margin-top:20px}
    .question_ask .clear{clear:none;}
	
	table.service_center{width:100%;}
	
	#list_town{width:95%}
	#list_town ul{width:96%;}
	
	.news{width:100%;display:block;height:auto;padding:10px 0;}
	.news h2{margin:0 0 10px 0}
	.news p {max-height:100%!important;min-height:auto;}
	.news .time{margin-bottom:0;}
	
	.contacts{width:100%;float:none;}
	
	.fleft_block, .partners, .online-shop, .filters{width:100%;float:none;}
	.filters .select{float:none;margin-right:0;margin-bottom:20px;z-index:auto!important;}
	.filters .slct{}
		
	.partners{height:auto;margin:0 0 20px 0;}
	
	.online-shop{margin-top:20px;}
	
	.compare{margin-left:4%;margin-right:4%;width:92%;}
	.compare_block{width:96%;float:none;display:block;overflow:hidden;margin:0 0 10px 0;padding:4px 1% 4px 2%;}
	
	.prod_left center, .quality, .prod_right{float:none;}
	.product_tabs{float:none;width:100%;}
	.product_tabs > input:nth-of-type(1):checked ~ div:nth-of-type(1), .product_tabs > input:nth-of-type(2):checked ~ div:nth-of-type(2), .product_tabs > input:nth-of-type(3):checked ~ div:nth-of-type(3), .product_tabs > input:nth-of-type(4):checked ~ div:nth-of-type(4){width:100%;}
	.product_tabs label{display: inline-block;float: none;margin:12px 0 0 0;width: 45%;}
	.product_tabs > input:nth-of-type(4):checked ~ div:nth-of-type(4) td:first-child{width:75%}
	.product_tabs > input:nth-of-type(4):checked ~ div:nth-of-type(4) td:last-child{text-align:left}
	
	.prod_right h1{margin-top:20px;margin-bottom:10px;}
	
	.center_downloads{width:100%;height:auto;display:block;}
	.center_downloads table{width:88%;}
	.center_downloads tr td:first-child{width:88%!important;display:inline-block;}
	.center_downloads tr td:last-child{width:12%!important;display:inline-block;}
	#downloadCenter.filters .select div{position:relative;max-height:150px;overflow-y:scroll;}
	
	.lb-prev{background: url(../img/lightbox/prev.png) left 48% no-repeat;display:block}
	.lb-next{background: url(../img/lightbox/next.png) right 48% no-repeat;display:block}
			
	.left-sidebar{display:none;}
    
	 footer{height:auto;}
	.footer{width:100%;}
	.logo_f{display:none;}
	.block_f:first-child{bottom:-40px;position: absolute;text-align: center;width: 100%;background: #20324c;height:auto;}
	.block_f:nth-child(2n+2), .block_f:nth-child(3n+3) {width:49%;height:100%;}
	.block_f:last-child{float:none;width:100%;height:100%;padding-bottom:20px}
	.foot_search{padding:7% 4%;background:#1b2b42;margin:40px 0;display:block;width:92%;position:absolute;top:180px;}
	footer .search-form{width:68%;margin:0 auto;float:none;}
	footer .search-form input[type="text"]{background:#1b2b42;}
	footer .phone{float:none;margin:300px auto 0 auto;display:block;overflow:hidden;width:140px;}
	}
.soc{
	margin-bottom: 35px;
	display: inline-block;
}
.soc a {
	width: 26px;
	height: 26px;
	background-image: url(../img/soc1.png);
	display: block;
	float: left;
	margin: 2px;
	margin-right: 8px;
}
.soc a:hover, .soc a:focus {
	transition: none;
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	-ms-transition: none;
}
.soc .fb:hover {
	background-position: 0 -26px;
}
.soc .vk {
	background-position: -29px 0;
}
.soc .vk:hover {
	background-position: -29px -26px;
}
.soc .yt {
	background-position: -58px 0;
}
.soc .yt:hover {
	background-position: -58px -26px;
}
@media (max-width:480px) and (min-width: 320px){
	.soc{
	margin-bottom: -5px;
	}
}